import React from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import Toast from "light-toast";
import { Helmet } from "react-helmet";
import axios from "axios";
import Loader from "../Loader";
import { axiosInstance, axiosInstanceAuth } from "../../utils/Service";

import {
  signUpRequest,
  socialLogin,
  showSignupErr,
  OTPVerify,
  loginRequest,
  resendSignOTP,
  resendEmailLink,
  OTPVerifyEmail,
} from "./../../store/actions/common";
import { detect } from "detect-browser";
import { SET_SIGNUP_USER_ERROR } from "./../../store/actionsTypes";
import { useToasts } from "react-toast-notifications";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import OTPForm from "./OTPform";
import { getProfileBannerInfo } from "../../store/actions/profile";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
// import Select from 'react-select';
import "react-phone-number-input/style.css";
//import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import startsWith from "lodash.startswith";
import { createRef } from "react";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    let isSuperAdminMode = false;
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    if (
      queryParams.has("superadmin") &&
      queryParams.get("superadmin") === "true"
    ) {
      isSuperAdminMode = true;
    }

    this.state = {
      isSuperAdminMode,
      companyName: "",
      firstName: "",
      lastName: "",
      password: "",
      password2: "",
      showPass1: false,
      showPass2: true,
      email: "",
      mobile: "",
      dob: "",
      checkbox: false,
      checkbox2: false,
      showInputForm: false,
      error: false,
      error2: false,
      ip: "",
      errorMsg: "",
      errorMsg2: "",
      status: "",
      refferal_code: "",
      passNotMatch: false,
      OTPForm: false,
      showOTPform: false,
      OTP: "",
      emailOTP: "",
      token: "", // for otp user
      time: 50, //timer for resend otp
      showCalendar: false,
      gender: "",
      showLoader: false,
      showMessage: "",
      invalidEmail: false,
      fullName: "",
      invalidPassword: false,
      verifiedMobileOTP: false,
      verifiedEmailOTP: false,
      value: "",
      userType: "Buyer",
      dialCode: "",
      mobileApi: "",
      secondStep: false,
      firstStep: true,
      addressOne: "",
      addressOneError: false,
      state: "",
      stateError: false,
      city: "",
      cityError: false,
      zipCode: "",
      zipCodeError: false,
      PAN: "",
      panError: false,
      panValidError: false,
      cin: "",
      cinError: false,
      cinValidError: false,
      gstNumber: "",
      gstError: false,
      selectedImagePan: null,
      panImageError: false,
      selectedCINImage: null,
      selectedImageGst: null,
      gstImageError: false,
      checkbox2Error: false,
      stateList: [],
      zipCodeValidError: false,
    };
    this.companyNameRef = createRef();
    this.firstNameRef = createRef();
    this.lastNameRef = createRef();
    this.emailRef = createRef();
    this.passwordRef = createRef();
  }

  componentDidMount() {
    // fetch("https://api.ipify.org?format=json")
    //   .then((res) => res.json())
    //   .then((json) => {
    //     this.setState({ ip: json.ip });
    //   });
    axiosInstance.get("category/country").then((res) => {
      this.setState({ stateList: res.data.data[0].stateData });
    });
  }

  changeInput = (e) => {
    if (e.target.name === "companyName") {
      const re = /^[A-Za-z/\W|_/g ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "firstName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "lastName") {
      const re = /^[A-Za-z ]+$/;
      if (e.target.value === "" || re.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value, status: false });
      }
    } else if (e.target.name === "password") {
      const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      let checkSpecial = specialChars.test(e.target.value);

      function hasUpperCase(str) {
        return /[A-Z]/.test(str);
      }

      function hasLowerCase(str) {
        return /[a-z]/.test(str);
      }

      // const upperCase = /^[A-Z]*$/;
      // let checkUpperCase = upperCase.test(e.target.value);

      const number = /\d/;
      let checkNumber = number.test(e.target.value);

      if (
        checkSpecial &&
        hasUpperCase(e.target.value) &&
        hasLowerCase(e.target.value) &&
        checkNumber &&
        e.target.value.length >= 8
      ) {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: false,
        });
      } else {
        this.setState({
          [e.target.name]: e.target.value,
          invalidPassword: true,
        });
      }
    } else this.setState({ [e.target.name]: e.target.value, status: false });
  };

  changeInputEmail = (e) => {
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (filter.test(e.target.value)) {
      this.setState(
        { [e.target.name]: e.target.value, status: false, invalidEmail: false },
        () =>
          this.props.showSignupErr({
            type: SET_SIGNUP_USER_ERROR,
            payload: { errData: "", status: false },
          })
      );
      this.props.showSignupErr({
        type: SET_SIGNUP_USER_ERROR,
        payload: { errData: "", status: false },
      });
    } else {
      if (e.target.value.length > 0) {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: true });
      } else {
        this.setState({ [e.target.name]: e.target.value, invalidEmail: false });
      }
    }
  };

  checkboxState = () => {
    this.setState({ checkbox: !this.state.checkbox, error: false });
  };

  checkboxState2 = () => {
    this.setState({ checkbox2: !this.state.checkbox2, checkbox2Error: false });
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) this.setState({ showLoader: false });
        this.props.addToast("Signed with Facebook", { appearance: "success" });
        this.props.closePopup(true);
      });
    }
  };

  responseGoogle = (response) => {
    const data = response.profileObj;
    const first_name = data.givenName;
    const last_name = data.familyName;
    const email = data.email;
    const socialId = data.googleId;
    const payload = {
      first_name,
      last_name,
      email,
      socialId,
      provider: "google",
    };
    this.props.socialLogin(payload, (status) => {
      if (status) this.setState({ showLoader: false });
      this.props.addToast("Signed with Google", { appearance: "success" });
      this.props.closePopup(true);
    });
  };

  enterPhone = (e, code) => {
    let temp = e;
    this.setState({ mobile: temp });
    // let temp = e;
    // let checkDigit = new RegExp(/^\d*$/);
    // if (checkDigit.test(temp)) {
    //   this.setState({ mobile: temp });
    // }
  };

  handleEnter = (event) => {
    if (event.keyCode === 13) {
      const form = event.target.form;
      const index = Array.prototype.indexOf.call(form, event.target);
      form.elements[index + 1].focus();
      event.preventDefault();
    }
  };

  submitOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const params = {
      otp: this.state.OTP,
      token: this.state.token,
    };
    this.props.OTPVerify(params, (callback) => {
      if (callback.data.status) {
        this.setState({ showLoader: false });
        this.setState({ showOTPform: true, verifiedMobileOTP: true });

        this.props.addToast(
          callback.data.message,
          { appearance: "success" },
          () => {
            this.setState({ showOTPform: false });
          }
        );
        setTimeout(() => {
          localStorage.setItem(
            "approved",
            callback.data && callback.data.userData.Approval
          );
          localStorage.setItem("pendinModal", true);
          //localStorage.setItem("isProfileCompleted", callback.data && callback.data.userData.isProfileCompleted);
          window.location.reload();
        }, 1000);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(callback.data.message, { appearance: "error" });
      }
    });
  };

  submitOTPEmail = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const params = {
      otp: this.state.emailOTP,
      token: this.state.token,
    };
    this.props.OTPVerifyEmail(params, (callback) => {
      if (callback.status) {
        this.setState({ showLoader: false });
        this.setState({ showOTPform: true, verifiedEmailOTP: true });

        this.props.addToast(
          callback.data.message,
          { appearance: "success" },
          () => {
            this.setState({ showOTPform: false });
            //this.props.closePopup(false);
          }
        );
        // this.loginFunction();

        setTimeout(() => {
          if (this.state.verifiedEmailOTP && this.state.verifiedMobileOTP) {
            localStorage.setItem(
              "isProfileCompleted",
              callback.data && callback.data.userData.isProfileCompleted
            );
            // this.props.history.push("/sellerRegistration/" + "2wefrt433f257354f34");
            // this.props.closePopup(true);
            window.location.reload();
          }
        }, 1000);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(callback.data.message, { appearance: "error" });
      }
    });
  };

  resendMobOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });

    this.props.resendSignOTP({ token: this.state.token }, (callback) => {
      if (callback) {
        this.setState({ showLoader: false });
        this.props.addToast(callback.message, { appearance: "success" });
        this.setState({ time: 50 });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  resendEmailOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });

    this.props.resendSignOTP({ token: this.state.token }, (callback) => {
      if (callback) {
        this.setState({ showLoader: false });
        this.props.addToast(callback.message, { appearance: "success" });
        this.setState({ time: 50 });
      } else {
        this.setState({ showLoader: false });
      }
    });
  };

  resendEmail = () => {
    this.props.resendEmailLink({ token: this.state.token }, (callback) => {
      this.props.addToast(callback.message, { appearance: "success" });
      this.setState({ time: 50 });
    });
  };

  loginFunction = () => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    const payload = {
      email: this.state.email,
      password: this.state.password,
    };
    localStorage.setItem("rememberMe", true);
    localStorage.setItem("bazaarEmail", payload.email);
    localStorage.setItem("bazaarPass", payload.password);

    this.props.loginRequest(payload, (status) => {
      if (status.status) {
        this.props.getProfileBannerInfo();
        window.location.reload();
        this.setState({ showLoader: false });
        this.props.addToast("Login Successfully", { appearance: "success" });
        this.props.closePopup(false);
        this.props.addToast(status.data.errData, { appearance: "error" });
      }
    });
  };

  ChangeFormateDate = () => {
    const [dd, mm, yy] = this.state.dob.split(/-/g);
    return `${mm}-${dd}-${yy}`;
  };
  setDateOfBirth = (props) => {
    const newDOB =
      props.getDate() +
      "-" +
      (props.getMonth() + 1) +
      "-" +
      props.getFullYear();
    this.setState({ showCalendar: !this.state.showCalendar, dob: newDOB });
  };

  backHandler = () => {
    this.setState({ OTPForm: false });
  };

  getCode = (item) => { };

  buyerHandle = (e) => {
    this.setState({ userType: e.target.value });
  };

  sellerHandle = (e) => {
    this.setState({ userType: e.target.value });
  };

  nextStep = () => { };

  addressOnehandler = (e) => {
    this.setState({ addressOne: e.target.value });
    this.setState({ addressOneError: false });
  };
  cityhandler = (e) => {
    this.setState({ city: e.target.value });
    this.setState({ cityError: false });
  };
  statehandler = (e) => {
    this.setState({ state: e.target.value });
    this.setState({ stateError: false });
  };
  zipcodehandler = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value.length < 6) {
        this.setState({
          zipCodeValidError: true,
          zipCode: e.target.value,
          zipCodeError: false,
        });
      } else {
        this.setState({ zipCode: e.target.value, zipCodeValidError: false });
        this.setState({ zipCodeError: false });
      }
    } else {
    }
  };
  panChangeHandler = (e) => {
    let regex = new RegExp(/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/);

    if (!regex.test(e.target.value)) {
      this.setState({ PAN: e.target.value });
      this.setState({ panValidError: true });
      this.setState({ panError: false });
    } else {
      this.setState({ PAN: e.target.value });
      this.setState({ panValidError: false });
    }

    // this.setState({ PAN: e.target.value });
    // console.log("3333")
    // this.setState({ panError: false });
    // console.log("4444")
  };
  cinChangeHandler = (e) => {

    let regex = new RegExp(/^([LUu]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/);

    if (!regex.test(e.target.value)) {
      this.setState({ cin: e.target.value });
      this.setState({ cinValidError: true });
      this.setState({ cinError: false })
    }
    else {  
      this.setState({ cin: e.target.value })
      this.setState({ cinValidError: false })
    }

    // this.setState({ cin: e.target.value });
    // this.setState({ cinError: false });
  };
  gstNumberChangeHandler = (e) => {
    this.setState({ gstNumber: e.target.value });
    this.setState({ gstError: false });
  };
  // panImageHandler = (e) => {
  //   this.setState({ selectedImagePan: e.target.files[0] });
  //   this.setState({ panImageError: false });
  // };
  // cinImageHandler = (e) => {
  //   this.setState({ selectedCINImage: e.target.files[0] });
  // };
  // gstImageHandler = (e) => {
  //   this.setState({ selectedImageGst: e.target.files[0] });
  //   this.setState({ gstImageError: false });
  // };

  handleNextClick = () => {
    if (this.state.companyName.length === 0) {
      this.companyNameRef.current.focus();
    } else if (this.state.firstName.length === 0) {
      this.firstNameRef.current.focus();
    } else if (this.state.lastName.length === 0) {
      this.lastNameRef.current.focus();
    } else if (this.state.email.length === 0) {
      this.emailRef.current.focus();
    } else if (this.state.password.length === 0) {
      this.passwordRef.current.focus();
    } else if (this.state.invalidEmail) {
      return null;
    } else if (this.state.invalidPassword) {
      return true;
    } else {
      this.setState({ firstStep: false, secondStep: true });
    }
  };

  // formSubmit = (e) => {
  //   e.preventDefault();
  //   const formData = new FormData();
  //   formData.append('companyName', this.state.companyName);
  //   formData.append('first_name', this.state.firstName);
  //   formData.append('last_name', this.state.lastName);
  //   formData.append('password', this.state.password);
  //   formData.append('mobile', this.state.mobile);
  //   formData.append('email', this.state.email);
  //   formData.append('countryCode', '+91');
  //   formData.append('userType', this.state.userType);
  //   formData.append('gstNumber', this.state.gstNumber);
  //   formData.append('PAN', this.state.PAN);
  //   formData.append('cin', this.state.cin);
  //   formData.append('addressLine1', this.state.addressOne);
  //   formData.append('zipCode', this.state.zipCode);
  //   formData.append('state', this.state.state);
  //   formData.append('city', this.state.city);
  //   formData.append('PANImages', this.state.selectedImagePan);
  //   formData.append('gstFile', this.state.selectedImageGst);
  //   formData.append('cinImage', this.state.selectedCINImage);

  //   // need function for api
  //   axiosInstanceAuth.post('newSignupApi', formData).then((res) => {

  //     // uncomment below code to close popup on successful signup
  //     // this.props.closePopup(true);
  //   });

  //   // e.preventDefault();
  //   // if (this.state.invalidEmail) {
  //   //   return null
  //   // }
  //   // else if (this.state.invalidPassword) {
  //   //   return true
  //   // }
  //   // //  else if (this.state.password2 !== this.state.password) {
  //   // //   this.setState({ passNotMatch: true });
  //   // // }
  //   // else {
  //   //   // this.setState({ firstStep: false, secondStep: true })
  //   //   //this.setState({ error: true });
  //   // }
  // };

  formSubmit = (e) => {
    e.preventDefault();
    if (this.state.invalidEmail) {
      return null;
    } else if (this.state.invalidPassword) {
      return true;
    } else if (this.state.addressOne == "") {
      this.setState({ addressOneError: true });
      document.getElementById("addressOne").focus();
      return false;
    } else if (this.state.city == "") {
      this.setState({ cityError: true });
      document.getElementById("city").focus();
      return false;
    } else if (this.state.state == "") {
      this.setState({ stateError: true });
      document.getElementById("state").focus();
      return false;
    } else if (this.state.zipCode == "") {
      this.setState({ zipCodeError: true, zipCodeValidError: false });
      document.getElementById("zip").focus();
      return false;
    } else if (this.state.zipCode.length < 6) {
      this.setState({ zipCodeValidError: true, zipCodeError: false });
      document.getElementById("zip").focus();
      return false;
    } 
    // else if (this.state.PAN == "") {
    //   this.setState({ panError: true });
    //   document.getElementById("pan").focus();

    //   return false;
    // }
    // else if (this.state.panValidError) {
    //   document.getElementById("pan").focus();

    //   return false;
    // }

    // else if (this.state.cinValidError) {
    //   document.getElementById("cin").focus();
    //   return false;
    // }
    // else if (this.state.cin == "") {
    //   this.setState({ cinError: true });
    //   document.getElementById("cin").focus();
    //   return false;
    // } 
    
    else if (this.state.gstNumber == "") {
      this.setState({ gstError: true });
      document.getElementById("gstNumber").focus();
      return false;
    } 
    // else if (!this.state.selectedImagePan) {
    //   this.setState({ panImageError: true });
    // } 
    // else if (!this.state.selectedImageGst) {
    //   this.setState({ gstImageError: true });
    // } 
    else if (this.state.checkbox == false) {
      this.setState({ error: true });
    } 
    else if (this.state.checkbox2) {
      this.setState({ passNotMatch: false });
      this.setState({ showLoader: true, showMessage: "Please wait..." });
      const browser = detect();
      const payload = {
        first_name: this.state.firstName,
        last_name: this.state.lastName,
        email: this.state.email,
        companyName: this.state.companyName,
        fullName: this.state.fullName,
        password: this.state.password,
        mobile: this.state.mobileApi,
        userType: this.state.userType,
        countryCode: this.state.dialCode,
        addressLine1: this.state.addressOne,
        city: this.state.city,
        state: this.state.state,
        zipCode: this.state.zipCode,
        // PAN: this.state.PAN,
        // cin: this.state.cin,
        gstNumber: this.state.gstNumber,
      };

      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }
      // formdata.append("PANImages", this.state.selectedImagePan);
      // formdata.append("gstFile", this.state.selectedImageGst);
      // formdata.append("cinImage", this.state.selectedCINImage);

      this.props.signUpRequest(formdata, (status) => {
        if (status.res.status === "success") {
          this.setState({ showLoader: false });
          if (
            Object.keys(status.res).includes("mobileVerified") ||
            Object.keys(status.res).includes("sentOtp")
          ) {
            //if response contain {mobileVerified:false} ex mobile otp related
            this.setState({
              OTPForm: true,
              token: status.res.token,
              firstStep: false,
              secondStep: false,
            });
          }
          // this.props.addToast(status.res.message ? status.res.message : "OTP sent on your mobile and email", { appearance: "success" });
        } else {
          this.setState({
            errorMsg: status.res.message,
            status: status.res.status,
          });
          this.setState({ showLoader: false });
          this.props.addToast(status.res.message, { appearance: "error" });
          // Toast.fail("SignUp Fail..", 1000);
        }
      });
    } else {
      this.setState({ checkbox2Error: true });
    }
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) this.setState({ showLoader: false });
        this.props.addToast("Signed with Facebook", { appearance: "success" });
        this.props.closePopup(true);
      });
    }
  };

  back = () => {
    this.setState({ firstStep: true, secondStep: false });
  };

  render() {
    const {
      isSuperAdminMode,
      firstName,
      lastName,
      password,
      showPass1,
      password2,
      showPass2,
      email,
      error,
      error2,
      status,
      errorMsg,
      passNotMatch,
      mobile,
      showOTPform,
      OTP,
      time,
      dob,
      showCalendar,
      gender,
    } = this.state;

    return (
      <>
        {this.state.showLoader ? (
          <Loader message={this.state.showMessage} />
        ) : null}
        <div
          className="outside-layer"
          onClick={() => this.props.closePopup(true)}
        ></div>
        <div
          className={
            "signup-form signup-frm-wrp signup-page-wrapp signup-new-wrap" +
            (showOTPform ? " otp-open" : "") +
            (this.state.OTPForm ? " otp-open" : "")
          }
        >
          <Helmet>
            <style>
              {`
           .facebook-button-holder, .google-button-holder {
            position: relative;
           }
            .facebook-button-holder span button, .google-button-holder button:first-child {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 15;
              width: 100%;
              height: 100%;
             opacity:0 !important;
            }
            `}
            </style>
          </Helmet>

          <button
            className="close-btn"
            // onClick={() => this.props.closePopupAll()}
            onClick={() => this.props.closePopup(true)}
          >
            <i className="fal fa-times-circle"></i>
          </button>

          <div
            className="inner-sign signup-without-img"
            style={showOTPform ? { justifyContent: "space-around" } : {}}
          >
            {/* <div className="sign-img">

            <div className="sign-opp-wrap">
              <div className="facebook-button-holder">
                <FacebookLogin
                  appId={process.env.REACT_APP_FACEBOOK_CLIENT_ID}
                  autoLoad={false}
                  fields="name,email,picture"
                  // onClick={this.componentClicked}
                  callback={this.responseFacebook}
                />
                <button className="register-facebook mail-button">
                  <span className="signup-fb-text">
                    <i className="fab fa-facebook-f"></i> &nbsp; Sign up with
                    Facebook
                  </span>
                </button>
              </div>
              <div className="google-button-holder">
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  buttonText="Login"
                  onSuccess={this.responseGoogle}
                  onFailure={this.responseGoogle}
                  cookiePolicy={"single_host_origin"}
                />
                <button className="register-google mail-button">
                  <span>
                    <i className="google-icn">
                      <img
                        src="/assets/images/google.svg"
                        alt=""
                        height="15"
                        width="15"
                      />
                    </i>
                    Sign up with Google
                  </span>
                </button>
              </div>
            </div>
          </div> */}

            <div className="sign-form">
              {this.state.OTPForm ? (
                <OTPForm
                  OTP={OTP}
                  EmailOTP={this.state.emailOTP}
                  setState={(key, val) => this.setState({ [key]: val })}
                  submitOTP={this.submitOTP}
                  submitOTPEMAIL={this.submitOTPEmail}
                  time={time}
                  resendMobOTP={this.resendMobOTP}
                  resendEmail={this.resendEmail}
                  backHandler={this.backHandler}
                  mobileNumber={this.state.mobile}
                  verifyMobileOTP={this.state.verifiedMobileOTP}
                  verifyEmailOTP={this.state.verifiedEmailOTP}
                />
              ) : null}
              <form autoComplete="off" onSubmit={(e) => this.formSubmit(e)}>
                {this.state.firstStep ? (
                  <div className="detail-from signup-detail-form sign-up-form-wrap">
                    <div className="user-detail-edit signup-head-wrap">
                      <div className="logo-wrapper">
                        <Link to="/" className="logo-wrap">
                          <img
                            src="/assets/images/metal-buy-logoo.png"
                            alt="logo"
                          />
                        </Link>
                      </div>
                      {isSuperAdminMode && <div className="select-user-wrap">
                        <h3>Signing up as: </h3>
                        <div className="choice-wrap">
                          <div className="radio-box">
                            <div className="radio-wrap">
                              <input
                                type="radio"
                                name="user"
                                id="buyer"
                                value="Buyer"
                                onChange={(e) => this.buyerHandle(e)}
                                checked={
                                  this.state.userType == "Buyer" ? true : false
                                }
                              />
                              <span className="custom"></span>
                            </div>
                            <label htmlFor="buyer" className="label">
                              Buyer
                            </label>
                          </div>
                          <div className="radio-box">
                            <div className="radio-wrap">
                              <input
                                type="radio"
                                name="user"
                                id="seller"
                                value="Seller"
                                onChange={(e) => this.sellerHandle(e)}
                                checked={
                                  this.state.userType == "Seller" ? true : false
                                }
                              />
                              <span className="custom"></span>
                            </div>
                            <label htmlFor="seller" className="label">
                              Seller
                            </label>
                          </div>
                        </div>
                      </div>}
                    </div>
                    <div className="form-block-wrap">
                      <div className="enter-mobile-label">Company Name*</div>
                      <div className="form-input">
                        <TextField
                          variant="outlined"
                          type="text"
                          name="companyName"
                          // label="Company Name*"
                          placeholder="Enter company name here"
                          value={this.state.companyName}
                          onChange={(e) => this.changeInput(e)}
                          onKeyDown={this.handleEnter}
                          required
                          InputProps={{
                            inputProps: {
                              ref: this.companyNameRef,
                              maxLength: 100,
                              minLength: 2,
                            },
                          }}
                        />
                      </div>
                    </div>
                    {/* <div className="form-input">
                    <TextField
                      variant="outlined"
                      required
                      type="text"
                      name="firstName"
                      label="First Name*"
                      value={firstName}
                      onChange={(e) => this.changeInput(e)}
                      onKeyDown={this.handleEnter}
                      InputProps={{
                        inputProps: {
                          maxLength: 30,
                          minLength: 2,
                        },
                      }}
                    />
                  </div> */}
                    <div className="names-wrapper">
                      <div className="form-block-wrap">
                        <div className="enter-mobile-label">First Name*</div>
                        <div className="form-input">
                          <TextField
                            required
                            variant="outlined"
                            type="text"
                            name="firstName"
                            // label="Full Name*"
                            placeholder="Enter first name"
                            value={this.state.firstName}
                            onChange={(e) => this.changeInput(e)}
                            onKeyDown={this.handleEnter}
                            InputProps={{
                              inputProps: {
                                ref: this.firstNameRef,
                                maxLength: 30,
                                minLength: 2,
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="form-block-wrap">
                        <div className="enter-mobile-label">Last Name*</div>
                        <div className="form-input">
                          <TextField
                            required
                            variant="outlined"
                            type="text"
                            name="lastName"
                            // label="Full Name*"
                            placeholder="Enter last name"
                            value={this.state.lastName}
                            onChange={(e) => this.changeInput(e)}
                            onKeyDown={this.handleEnter}
                            InputProps={{
                              inputProps: {
                                ref: this.lastNameRef,
                                maxLength: 30,
                                minLength: 2,
                              },
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-block-wrap">
                      <div className="enter-mobile-label">Email*</div>
                      <div className="form-input">
                        <TextField
                          variant="outlined"
                          required
                          type="email"
                          name="email"
                          placeholder="Enter email address"
                          // label="Email Address or Mobile No.*"
                          value={email}
                          onChange={(e) => this.changeInputEmail(e)}
                          onKeyDown={this.handleEnter}
                          inputProps={{
                            ref: this.emailRef,
                          }}
                        />
                        {this.state.invalidEmail ? (
                          <span className="error">
                            Enter a valid email address
                          </span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className="form-input">
                        <TextField
                          variant="outlined"
                          required
                          type="text"
                          name="dob"
                          readOnly
                          placeholder="Date of Birth*"
                          value={dob}
                          onClick={() => this.setState({ showCalendar: !showCalendar })}
                          onKeyDown={this.handleEnter}
                        />
                        <i
                          onClick={() => this.setState({ showCalendar: !showCalendar })}
                          className="toggle-password fas fa-calendar-alt"
                        />
                        {showCalendar && (
                          <div className="popCalendar">
                            <Calendar
                              onChange={this.setDateOfBirth}
                              minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 120))}
                              maxDate={new Date()}
                              value={
                                dob
                                  ? new Date(this.ChangeFormateDate())
                                  : new Date("01-01-2000")
                              }
                            />
                          </div>
                        )}
                      </div> */}

                    {/* <FormControl className="form-input">
                        <InputLabel variant="outlined" >Gender</InputLabel>
                        <Select
                          required
                          variant="outlined"
                          value={gender}
                          label="Gender"
                          onChange={(e) => this.setState({ gender: e.target.value })}
                        >
                          <MenuItem value={"Male"}>Male</MenuItem>
                          <MenuItem value={"Female"}>Female</MenuItem>
                          <MenuItem value={"Others"}>Other</MenuItem>
                        </Select>
                      </FormControl> */}
                    <div className="enter-mobile-label">Mobile</div>
                    <div className="form-input number-code-wrapper signup-phone-input signup-code">
                      {/* <PhoneInput
                      international
                      placeholder="Enter mobile no.*"
                      value={this.state.mobile}
                      onChange={(e, code) => this.enterPhone(e)} /> */}

                      <PhoneInput
                        country={"in"}
                        disableDropdown={true}
                        placeholder="Enter mobile no."
                        className={this.state.mobile && "arrow-hide"}
                        enableSearch={true}
                        value={this.state.mobile}
                        onChange={(phone, country) => {
                          const reducedPhone = phone.replace(
                            country.dialCode,
                            ""
                          );
                          this.setState({
                            mobile: phone,
                            mobileApi: reducedPhone,
                            dialCode: country.dialCode,
                          });
                        }}
                        isValid={(value, country) => {
                          if (value.length === 0) {
                            return false;
                          } else {
                            return true;
                          }
                        }}
                        inputProps={{
                          required: true,
                        }}
                      />

                      {/* {this.state.mobile ? (isValidPhoneNumber(this.state.mobile) ? undefined : 'Invalid phone number') : 'Phone number required'} */}
                    </div>
                    {/* <div className="enter-mobile-label">Mobile*</div>
                  <div className="form-input number-code-wrapper signup-phone-input signup-code">
                    <span className="number-code">
                      +91
                    </span>
                    <TextField
                      variant="outlined"
                      type="text"
                      required
                      InputProps={{
                        inputProps: {
                          maxLength: 10,
                          minLength: 10,
                          autoComplete: "new-password",
                        },
                      }}
                      label="Enter mobile no.*"
                      value={mobile}
                      onKeyDown={this.handleEnter}
                      onChange={(e) => this.enterPhone(e)}
                    />
                  </div> */}
                    <div className="form-block-wrap">
                      <div className="enter-mobile-label">Password*</div>
                      <div className="form-input">
                        <TextField
                          variant="outlined"
                          required
                          type={showPass1 ? "text" : "password"}
                          name="password"
                          // label="Password*"
                          placeholder="Enter password"
                          value={password}
                          InputProps={{
                            inputProps: {
                              ref: this.passwordRef,
                              maxLength: 15,
                              minLength: 6,
                              autoComplete: "new-password",
                            },
                          }}
                          onChange={(e) => this.changeInput(e)}
                          onKeyDown={this.handleEnter}
                        />
                        <i
                          onClick={() =>
                            this.setState({ showPass1: !showPass1 })
                          }
                          className={`${showPass1 ? "fal fa-eye" : "far fa-eye-slash"
                            }`}
                        />
                      </div>
                      {/* <div className="enter-mobile-label">Confirm Password</div>
                      <div className="form-input">
                        <TextField
                          variant="outlined"
                          required
                          type={showPass2 ? "password" : "text"}
                          name="password2"
                          placeholder="Enter password"
                          // label="Confirm Password*"
                          value={password2}
                          InputProps={{
                            inputProps: {
                              maxLength: 15,
                              minLength: 6,
                              autoComplete: "new-password",
                            },
                          }}
                          onChange={(e) => this.changeInput(e)}
                          onKeyDown={this.handleEnter}
                        />
                        <i
                          onClick={() => this.setState({ showPass2: !showPass2 })}
                          className={`${showPass2 ? "far fa-eye-slash" : "fal fa-eye"
                            }`}
                        />
                        {passNotMatch && password2 !== password && (
                          <span className="error">
                            Password and Confirm Password doesn't match{" "}
                          </span>
                        )}
                      </div> */}
                      {/* <div className="cond signup-terms-condition">
                    <input
                      type="checkbox"
                      className="signup-checkbox"
                      checked={this.state.checkbox}
                      onChange={this.checkboxState}
                      onKeyDown={this.handleEnter}
                    />{" "}
                    I agree to Bazaar{" "}
                    <Link className="terms"
                      target="_blank"
                      to={{ pathname: "/page/terms", param: "Term & Condition" }}
                    >
                      Terms and Conditions
                    </Link>
                    &nbsp; &amp;{" "}
                    <Link className="privacy"
                      target="_blank"
                      to={{ pathname: "/policy", param: "Privacy" }}
                    >
                      Privacy Policy
                    </Link>
                    .
                    {error && (
                      <div className="error">
                        Please accept Terms & Conditions and Privacy Policy{" "}
                      </div>
                    )}
                  </div> */}
                      {/* {status && <span className="error">{errorMsg}</span>} */}
                      {this.state.invalidPassword ? (
                        <div className="passwrd-text">
                          <p>
                            Password should contain at least one Upper case, one
                            lowercase, one special character and one numeric
                            character and password length should be minimum 8
                            character
                          </p>
                        </div>
                      ) : null}
                    </div>

                    {/* <Link to="/checkout">
                  <h3>Alok</h3>
                  </Link> */}

                    <div className="login-btn-wrapper">
                      <button
                        type={"submit"}
                        className="shop-now"
                        onClick={this.handleNextClick}
                      >
                        Next
                      </button>
                      {/* <button className="shop-now" onClick={()=>{this.demo()}}>DEmo </button> */}
                    </div>
                    {/* <div className="login-condition">
                    <p>Don’t have an account? <button className="sign-up-here">Sign up here</button></p>
                  </div> */}
                  </div>
                ) : this.state.secondStep ? (
                  // second step of signup form
                  <>
                    <div className="login-description login-description-form-wrap address-field-wrap upload-document-wrapper">
                      <button
                        type="button"
                        className="btn-bck"
                        onClick={() => this.back()}
                      >
                        <i class="fas fa-long-arrow-left"></i>
                      </button>
                      <h3>Mailing Address</h3>
                      <div className="address-field-new-wrap">
                        <div className="addres-header-wrap">
                          {/* <h4>Manufacturing Plant Address</h4> */}
                        </div>
                        <div className="form-block-wrap">
                          <label className="enter-mobile-label">
                            Address line*
                          </label>
                          <div className="form-input">
                            <input
                              type="text"
                              placeholder="Type here"
                              onChange={(e) => this.addressOnehandler(e)}
                              id="addressOne"
                              value={this.state.addressOne}
                              maxLength="200"
                            />
                          </div>
                          {this.state.addressOneError ? (
                            <span className="error">
                              Please enter address line 1
                            </span>
                          ) : null}
                        </div>

                        <div className="form-block-wrap">
                          <div className="form-intput">
                            <label className="enter-mobile-label">City*</label>
                            <input
                              type="text"
                              placeholder="Enter city name here"
                              onChange={(e) => this.cityhandler(e)}
                              id="city"
                              value={this.state.city}
                              maxLength="100"
                            />
                          </div>
                          {this.state.cityError ? (
                            <span className="error">Please enter city</span>
                          ) : null}
                        </div>

                        {/* <div className='form-intput'>
                                                        <label>state</label>
                                                        <select onChange={(e) => statehandler(e)} id="state" disabled={profileComponent}>
                                                            <option value={""}>Please select state</option>
                                                            <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                            
                                                        </select>
                                                        
                                                    </div> */}
                        <div className="names-wrapper">
                          <div className="input-form-wrap select-affilation-profile address-name-wrap">
                            <div className="form-intput select-affilation-wrap">
                              <label className="enter-mobile-label">
                                State*
                              </label>

                              <div className="select-main-wrapper">
                                <select
                                  onChange={(e) => this.statehandler(e)}
                                  id="state"
                                >
                                  <option value={""}>Select</option>
                                  {this.state.stateList.length
                                    ? this.state.stateList.map(
                                      (item, index) => (
                                        <option value={item._id}>
                                          {item.name}
                                        </option>
                                      )
                                    )
                                    : null}
                                  {/* {
                                    stateDataArr && stateDataArr.stateData && stateDataArr.stateData.map(item => <option value={item._id} selected={state == item._id ? true : false}>{item.name}</option>)
                                  } */}
                                </select>
                                <i className="icon-arrow-down"></i>
                              </div>
                              {this.state.stateError ? (
                                <span className="error">
                                  Please select state
                                </span>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-block-wrap">
                            <div className="form-intput">
                              <label className="enter-mobile-label">
                                Pin code*
                              </label>
                              <input
                                type="text"
                                placeholder="Enter pincode here"
                                onChange={(e) => this.zipcodehandler(e)}
                                id="zip"
                                value={this.state.zipCode}
                                maxLength="6"
                              />
                            </div>
                            {this.state.zipCodeError ? (
                              <span className="error">
                                Please enter pin code
                              </span>
                            ) : null}
                            {this.state.zipCodeValidError ? (
                              <span className="error">
                                Please enter valid pin code
                              </span>
                            ) : null}

                            {/* {validPin ?
                              <span className="error">
                                {profileComponent ? null : "Please enter valid pin code"}
                              </span> : null
                            } */}
                          </div>
                        </div>
                      </div>
                      {/* <div className="form-block-wrap">
                        <div className="form-intput">
                          <label className="enter-mobile-label">
                            PAN number*
                          </label>
                          <input
                            type="text"
                            placeholder="Enter PAN number here"
                            onChange={(e) => this.panChangeHandler(e)}
                            id="pan"
                            value={this.state.PAN}
                            maxLength="10"
                          />
                        </div>
                        {this.state.panError ? (
                          <span className="error">Please enter PAN number</span>
                        )
                          : this.state.panValidError
                            ? (<span className="error">Please enter Valid PAN number</span>)
                            :
                            null}


                      </div>
                      <div className="form-block-wrap">
                        <div className="form-intput">
                          <label className="enter-mobile-label">
                            CIN number
                          </label>
                          <input
                            type="text"
                            placeholder="Enter CIN number here"
                            onChange={(e) => this.cinChangeHandler(e)}
                            id="cin"
                            value={this.state.cin}
                            maxLength="21"
                          />
                        </div>
                        {
                          this.state.cinValidError ? (<span className="error">Please enter Valid CIN number</span>) : null
                        }
                      </div> */}
                      <div className="form-block-wrap">
                        <div className="form-intput">
                          <label className="enter-mobile-label">
                            GST number*
                          </label>
                          <input
                            type="text"
                            placeholder="Enter GST number here"
                            onChange={(e) => this.gstNumberChangeHandler(e)}
                            id="gstNumber"
                            value={this.state.gstNumber}
                            maxLength="15"
                          />
                        </div>
                        {this.state.gstError ? (
                          <span className="error">Please enter GST number</span>
                        ) : null}
                      </div>
                      {/* <div className="login-description description-new-wrap upload-desc-wrapper">
                        <h2>Upload document</h2>
                        <div className="main-img-wrap">
                          <div className="form-intput input-upload-form">
                            {this.state.selectedImagePan ? (
                              <button
                                onClick={() =>
                                  this.setState({ selectedImagePan: null })
                                }
                                className="close-img-btn"
                              >
                                <i className="close-img-icon"></i>
                              </button>
                            ) : null}
                            <div className="select-edit-wrap">
                              {this.state.selectedImagePan && (
                                <div className="select-img-upload">
                                  <img
                                    alt="not found"
                                    src={URL.createObjectURL(
                                      this.state.selectedImagePan
                                    )}
                                  />
                                </div>
                              )}

                              {!this.state.selectedImagePan && (
                                <div className="input-file-upload-wrap">
                                  <div className="input-upload">
                                    <div className="file-image">
                                      <img
                                        src="/assets/images/choose-img-icon.svg"
                                        alt="icon"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <input
                                type="file"
                                className="file-upload-input"
                                accept=".jpg ,.jpeg,.png, .pdf"
                                name="myImage"
                                onChange={(event) => {
                                  this.panImageHandler(event);
                                }}
                              />
                            </div>
                            <div className="upload-label-wrap">
                              <label class="upload-label">Pan Card*</label>
                            </div>
                            {this.state.panImageError ? (
                              <span className="error">
                                Please select pan card image
                              </span>
                            ) : null}

                          </div>

                          <div className="form-intput input-upload-form">
                            {this.state.selectedImageGst ? (
                              <button
                                onClick={() =>
                                  this.setState({ selectedImageGst: null })
                                }
                                className="close-img-btn"
                              >
                                <i className="close-img-icon"></i>
                              </button>
                            ) : null}
                            <div className="select-edit-wrap">
                              {this.state.selectedImageGst && (
                                <div className="select-img-upload">
                                  <img
                                    alt="not fount"
                                    width={"250px"}
                                    src={URL.createObjectURL(
                                      this.state.selectedImageGst
                                    )}
                                  />
                                </div>
                              )}


                              {!this.state.selectedImageGst && (
                                <div className="input-file-upload-wrap">
                                  <div className="input-upload">
                                    <div className="file-image">
                                      <img
                                        src="/assets/images/choose-img-icon.svg"
                                        alt="icon"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <input
                                type="file"
                                name="myImage"
                                className="file-upload-input"
                                accept=".jpg ,.jpeg,.png, .pdf"
                                onChange={(event) => {
                                  this.gstImageHandler(event);
                                }}
                              />
                            </div>
                            <div className="upload-label-wrap">
                              <label className="upload-label">
                                GST Registration Certificate*
                              </label>
                            </div>

                            {this.state.gstImageError ? (
                              <span className="error">
                                Please select GST image
                              </span>
                            ) : null}
                          </div>
                          <div className="form-intput input-upload-form">
                            {this.state.selectedCINImage ? (
                              <button
                                onClick={() =>
                                  this.setState({ selectedCINImage: null })
                                }
                                className="close-img-btn"
                              >
                                <i className="close-img-icon"></i>
                              </button>
                            ) : null}
                            <div className="select-edit-wrap">
                              {this.state.selectedCINImage && (
                                <div className="select-img-upload">
                                  <img
                                    alt="not fount"
                                    width={"250px"}
                                    src={URL.createObjectURL(
                                      this.state.selectedCINImage
                                    )}
                                  />
                                </div>
                              )}

                              {!this.state.selectedCINImage && (
                                <div className="input-file-upload-wrap">
                                  <div className="input-upload">
                                    <div className="file-image">
                                      <img
                                        src="/assets/images/choose-img-icon.svg"
                                        alt="icon"
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              <input
                                type="file"
                                name="myImage"
                                className="file-upload-input"
                                accept=".jpg ,.jpeg,.png, .pdf"
                                onChange={(event) => {
                                  this.cinImageHandler(event);
                                }}
                              />
                            </div>
                            <div className="upload-label-wrap">
                              <label className="upload-label">CIN</label>
                            </div>
                          </div>
                        </div>
                        <span className="img-warning">
                          *file should be in pdf, png, jpg or jpeg format and maximum
                          2 mb in size
                        </span>
                      </div> */}
                      <>
                        {/* {previousNext ?
                            <div className="registration-btn registration-btn-wrpper">
                              <input className="btn shop-now go-back" type="button" value={"Go Back"} onClick={handlePrevious} />
                              <input className="btn shop-now next-btn" type="button" value={"Next"} onClick={nextValidation} />
                            </div> : null

                          } */}
                      </>
                    </div>
                    <div className="term-condition" style={{marginTop:"20px"}}>
                      <div className="term-condition-wrapper-box">
                        <input
                          type="checkbox"
                          className="signup-checkbox"
                          checked={this.state.checkbox}
                          onChange={this.checkboxState}
                          onKeyDown={this.handleEnter}
                        />
                        <label htmlFor="vehicle1">
                          {" "}
                          <p>
                            By clicking on submit you accept our{" "}
                            <Link
                              to="/page/terms"
                              className="condition-link"
                              target="_blank"
                            >
                              Terms of use
                            </Link> <span> & </span> <Link
                              to="/policy"
                              className="condition-link"
                              target="_blank"
                            >
                              Privacy policy
                            </Link>
                          </p>
                        </label>
                        <br />
                      </div>
                      <div className="privacy-error-msz">
                        {error && (
                          <div className="error">
                            Please accept Terms & Conditions and Privacy Policy{" "}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="term-condition">
                      <div className="term-condition-wrapper-box">
                        <input
                          type="checkbox"
                          className="signup-checkbox"
                          checked={this.state.checkbox2}
                          onChange={this.checkboxState2}
                          onKeyDown={this.handleEnter}
                        />
                        <label htmlFor="vehicle1">
                          {" "}
                          <p>
                            You agree and consent to the information provided by
                            you to be shared for background verification.
                          </p>
                        </label>
                        <br />
                      </div>
                      <div className="privacy-error-msz">
                        {this.state.checkbox2Error && (
                          <div className="error">
                            Please agree on the consent.
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="login-btn-wrapper">
                      <button className="shop-now">Submit</button>
                      {/* <button className="shop-now" onClick={()=>{this.demo()}}>DEmo </button> */}
                    </div>
                  </>
                ) : null}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    signUpRequest: (payload, completion) =>
      dispatch(signUpRequest(payload, completion)),
    // getToken:(payload, completion) =>
    //   dispatch(getToken(payload, completion)),
    OTPVerify: (payload, completion) =>
      dispatch(OTPVerify(payload, completion)),
    OTPVerifyEmail: (payload, completion) =>
      dispatch(OTPVerifyEmail(payload, completion)),
    resendSignOTP: (payload, compilation) =>
      resendSignOTP(payload, compilation),
    resendEmailLink: (payload, compilation) =>
      resendEmailLink(payload, compilation),
    loginRequest: (payload, completion) =>
      dispatch(loginRequest(payload, completion)),
    socialLogin: (payload, completion) =>
      dispatch(socialLogin(payload, completion)),
    showSignupErr: (payload) => dispatch(showSignupErr(payload)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
  status: state.userInfo.SignupError.status,
  errData: state.userInfo.SignupError.errData,
});
export default withRouter(
  withToast(connect(mapStateToProps, mapDispatchToProps)(Signup))
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
