import React, { useEffect, useState } from "react";
import WOW from "wowjs";
import "animate.css";
import { Link } from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import { API_URL, API_PREFIX } from "../../config2";

const PlatformProducts = () => {
  useEffect(() => {
    const wow = new WOW.WOW({
      live: true, // Enables the live mode to detect changes dynamically
    });
    wow.init();
  }, []);

  // State to manage the visibility of the form and email input
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);

  // Function to handle form submission and API call
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Validate email
    if (!email) {
      setEmailError('Email is required'); // Show error if email is empty
      return;
    }
  
    // Email validation regex
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.'); // Show error if email is invalid
      return;
    }
  
    // Reset email error if the validation is successful
    setEmailError(false);
  
    try {
      const url = `${API_URL}${API_PREFIX}auth/download-brochure`;
  
      const response = await fetch(
        url,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
  
      if (response.ok) {
        alert("You will receive the brochure on your email!");
        setShowForm(false); // Close the form after successful submission
      } else {
        alert("Failed to submit email. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    }
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    if (emailError && event.target.value) {
      setEmailError(false); // Clear the error once the user starts typing
    }
  };

  const [hover, setHover] = useState(false);

  const baseStyle = {
    background: "#ef7f1a",
    backgroundColor: "rgb(239, 127, 26)",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.16)",
    borderRadius: "4px",
    color: "#fff",
    padding: "16px 30px",
    width: "100%",
    fontWeight: "700",
    fontSize: "15px",
    transition: "all 0.5s",
    border: "1px solid transparent",
    textTransform: "none",
    fontFamily: '"Inter"',
    maxWidth: "320px",
    margin: "0 auto 15px",
  };

  const hoverStyle = {
    ...baseStyle,
    color: "rgb(239, 127, 26)", // Change color on hover
    backgroundColor: "transparent", // Change background color on hover
    border: "1px solid rgb(239, 127, 26)", // Stronger shadow on hover
  };

  return (
    <div className="product-listing-new">
      <div className="platform-product">
        <div className="container-main">
          <h3 className="text-center">PRODUCTS</h3>
          <div className="products-list">
            <Link
              to="/product-list"
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.1s"
            >
              <div className="material-img">
                <img src="/assets/images/mangenes.png" alt="metal-img" />
              </div>
              {/* <h4>Ferrochrome
            </h4> */}
              <h4>Manganese Ore</h4>
              <div className="trade-onhover">
                <strong>
                  PRODUCTS<i></i>
                </strong>
              </div>
            </Link>
            <Link
              to="/product-list"
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.1s"
            >
              <div className="material-img">
                <img
                  src="/assets/images/PlatformProducts2.png"
                  alt="metal-img"
                />
              </div>
              <h4>Silico Manganese</h4>
              <div className="trade-onhover">
                <strong>
                  PRODUCTS<i></i>
                </strong>
              </div>
            </Link>
            <Link
              to="/product-list"
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.2s"
            >
              <div className="material-img">
                <img
                  src="/assets/images/PlatformProducts3.png"
                  alt="metal-img"
                />
              </div>
              <h4>Ferro Manganese</h4>
              <div className="trade-onhover">
                <strong>
                  PRODUCTS<i></i>
                </strong>
              </div>
            </Link>
            <Link
              to="/product-list"
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.3s"
            >
              <div className="material-img">
                <img
                  src="/assets/images/PlatformProducts4.png"
                  alt="metal-img"
                />
              </div>
              <h4>Ferro Silicon</h4>
              <div className="trade-onhover">
                <strong>
                  PRODUCTS<i></i>
                </strong>
              </div>
            </Link>
            <Link
              to="/product-list"
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.4s"
            >
              <div className="material-img">
                <img
                  src="/assets/images/PlatformProducts5.png"
                  alt="metal-img"
                />
              </div>
              <h4>Ferro Chrome</h4>
              <div className="trade-onhover">
                <strong>
                  PRODUCTS<i></i>
                </strong>
              </div>
            </Link>
            <Link
              to="/product-list"
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.5s"
            >
              <div className="material-img">
                <img
                  src="/assets/images/PlatformProducts6.png"
                  alt="metal-img"
                />
              </div>
              <h4>Ferro Manganese Slag</h4>
              <div className="trade-onhover">
                <strong>
                  PRODUCTS<i></i>
                </strong>
              </div>
            </Link>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "38px",
            }}
          >
            <div
              className="trade-mnaterials wow animate__animated animate__fadeInUp "
              data-wow-delay="0.5s"
            >
              <div className="material-img">
                <img
                  src="/assets/images/PlatformProducts6.png"
                  alt="metal-img"
                />
              </div>
              <h4>Brochure</h4>
              <div className="trade-onhover" onClick={() => setShowForm(true)}>
                <strong>
                  Download<i></i>
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Popup Form */}
      {showForm && (
        <div className="popup-form" style={popupStyle}>
          <div
            className="popup-content"
            style={{
              padding: "20px",
              background: "#fff",
              borderRadius: "5px",
              position: "relative",
            }}
          >
            <h4>Enter your email to download the brochure</h4>
            <button
              class="close-btn"
              style={{ position: "absolute", top: "10px", right: "10px" }}
              onClick={() => setShowForm(false)}
            >
              <i class="fal fa-times-circle" style={{ fontSize: "large" }}></i>
            </button>
            <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
              <div className="form-input" style={{ margin: "30px 0" }}>
                <TextField
                  variant="outlined"
                  required
                  type="email" // Type should be 'email' for email validation
                  name="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={handleEmailChange}
                  maxLength="100"
                  fullWidth
                />
                {emailError && (
                  <span className="error" style={{ color: "red" }}>
                    Please enter a valid email.
                  </span>
                )}
              </div>
              <button
                type="submit"
                style={hover ? hoverStyle : baseStyle}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                Submit
              </button>
              {/* <button type="button" onClick={() => setShowForm(false)}>
                Cancel
              </button> */}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

// Style for the popup (you can also use CSS classes instead)
const popupStyle = {
  position: "fixed",
  top: "0",
  left: "0",
  right: "0",
  bottom: "0",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: "1000",
};

export default PlatformProducts;
