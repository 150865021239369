import React, { useState } from "react";
import { getShipping } from "../../store/actions/productDetails";
import { useDispatch } from "react-redux";
import Loader from "../Loader/index";
import { shippingModule } from "../../store/actions/shippingModule";
import { useToasts } from "react-toast-notifications";
import StepTimeline from "./StepTimeline";
import { returnLot } from "../../store/actions/returnLot";

const Shipping = (props) => {
  const [data, setData] = useState(null);
  const [enableForm, setEnableForm] = useState(true);
  const [editForm1, setEditForm1] = useState(false);
  const [initialForm1Save, setInitialForm1Save] = useState(false);
  const [initialForm2Save, setInitialForm2Save] = useState(false);
  const [editForm2, setEditForm2] = useState(false);

  React.useEffect(() => {
    if (
      props.orderData.shippingData[props.number - 1] &&
      props.orderData.shippingData[props.number - 1].lot_size
    ) {
      setEditForm1(false);
    } else {
      setEditForm1(true);
      setInitialForm1Save(true);
    }
  }, []);

  React.useEffect(() => {
    if (
      props.orderData.shippingData[props.number - 1] &&
      props.orderData.shippingData[props.number - 1].mediaImages
    ) {
      setEditForm2(false);
      setInitialForm2Save(false);
    } else {
      setEditForm2(true);
      setInitialForm2Save(true);
    }
  }, []);

  React.useEffect(() => {
    if (
      props.orderData.shippingData[props.number - 1] &&
      props.orderData.shippingData[props.number - 1].createdAt
    ) {
      setData(props.orderData.shippingData[props.number - 1]);
      let date_1 = new Date();
      let date_2 = new Date(
        props.orderData.shippingData[props.number - 1].createdAt
      );

      const days = (date_1, date_2) => {
        let difference = date_1.getTime() - date_2.getTime();
        let TotalDays = Math.floor(difference / (1000 * 3600 * 24));
        return TotalDays;
      };
      if (days(date_1, date_2) < 2) {
        setEnableForm(true);
      }
      if (days(date_1, date_2) > 1) {
        setEnableForm(false);
      }
    }
    if (
      props.orderData.shippingData[props.number - 1] &&
      props.orderData.shippingData[props.number - 1].driver_number
    ) {
      setEnableForm(true);
    }
  }, []);
  const [driverName, setDriverName] = useState("");
  const [lotSize, setLotSize] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [message, setMessage] = useState("");
  const [url, setUrl] = useState("");
  const [showErrorName, setShowErrorName] = useState(false);
  const [showErrorNumber, setShowErrorNumber] = useState(false);
  const [pickupDate, setPickupDate] = useState("");
  const [showErrorDate, setShowErrorDate] = useState(false);
  const [showErrorDriverNumber, setShowErrorDriverNumber] = useState(false);
  const [showErrorImage, setShowErrorImage] = useState(false);
  const [showErrorLotSize, setShowErrorLotSize] = useState(false);
  const [showErrorFile, setShowErrorFile] = useState(false);
  const [showErrorUrl, setShowErrorUr] = useState(false);
  const [img, setImg] = useState("");
  const [fileAttach, setFileAttach] = useState({});
  const [imageAttach, setImageAttach] = useState({});
  const dispatch = useDispatch();
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const [inputFields, setInputFields] = useState([{ vehicle: "" }]);
  const [submitDisable, setSubmitDisable] = useState(false);

  const [show, setShow] = useState(true);
  const { addToast } = useToasts();

  const imgHandler = (e, index) => {
    if (e.target.files[0]) {
      setImageAttach({ ...imageAttach, [index]: e.target.files[0] });
      let src_ = URL.createObjectURL(e.target.files[0]);
      setImg(src_);
      setShowErrorImage(false);
    } else {
      setImageAttach({ ...imageAttach, [index]: null });
    }
  };
  const fileHandler = (e, index) => {
    if (e.target.files[0]) {
      setFileAttach({ ...fileAttach, [index]: e.target.files[0] });
      let src_ = URL.createObjectURL(e.target.files[0]);
      setImg(src_);
      setShowErrorFile(false);
    } else {
      setFileAttach({ ...fileAttach, [index]: null });
    }
  };

  const fileTypes = [
    "GST invoice",
    "Lorry Receipt",
    "Weighment slip",
    "Material Test Certificate",
    "Ewaybill",
  ];

  const fileNames = [
    "gst_invoice",
    "lorry_receipt",
    "weightment_slip",
    "material_test_certificate",
    "eway_bill",
  ];

  const handleRemoveSingle = () => {
    setShow(false);
    props.activeAdd(true);
    props.handleRemoveLot();
  };

  const handleAdd = () => {
    setInputFields([
      ...inputFields,
      {
        title: "",
        description: "",
        location: "",
      },
    ]);
  };

  const handleRemove = (index) => {
    if (inputFields.length !== 1) {
      const values = [...inputFields];
      values.splice(index, 1);
      setInputFields(values);
    }
  };

  const handleDriverName = (e) => {
    setDriverName(e.target.value);
    setShowErrorName(false);
  };

  const handleLotSize = (e) => {
    setLotSize(e.target.value);
    setShowErrorLotSize(false);
  };

  const handleDriverNumber = (e) => {
    setDriverNumber(e.target.value);
    setShowErrorDriverNumber(false);
  };

  const handleVehicleNumber = (e) => {
    setVehicleNumber(e.target.value);
    setShowErrorNumber(false);
  };

  const handlePickupDate = (e) => {
    setPickupDate(e.target.value);
    setShowErrorDate(false);
  };

  const handleSubmitInitial = (e) => {
    e.preventDefault();
    if (lotSize == "") {
      setShowErrorLotSize(true);
      return false;
    }
    if (pickupDate == "") {
      setShowErrorDate(true);
      return;
    }
    if (driverName == "") {
      setShowErrorName(true);
      return false;
    }
    if (driverNumber == "") {
      setShowErrorDriverNumber(true);
      return;
    }
    if (vehicleNumber == "") {
      setShowErrorNumber(true);
      return;
    }
    setShowLoader(true);
    let payload = {
      orderId: props.orderData._id,
      lot_number: props.number,
      lot_size: lotSize,
      pickup_date: pickupDate,
      driver_name: driverName,
      driver_number: driverNumber,
      vehicle_number: vehicleNumber,
      tracking_url: url,
      tracking_status: "Ready To Ship",
      editForm1: initialForm1Save ? "" : editForm1,
    };
    let formdata = new FormData();
    for (let item in payload) {
      formdata.append(item, payload[item]);
    }
    formdata.append("gst_invoice", fileAttach["GST invoice"]);
    shippingModule(formdata, (status) => {
      if (status.status == "success") {
        setShowLoader(false);
        // setSubmitDisable(true)
        props.activeAdd(true);
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        window.location.reload(false);
      } else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })(dispatch);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    var expression =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    var regex = new RegExp(expression);

    // if (lotSize == "") {
    //   setShowErrorLotSize(true);
    //   return false;
    // }

    // if (pickupDate == "") {
    //   setShowErrorDate(true);
    //   return;
    // }

    if (Object.keys(fileAttach).length < 1) {
      setShowErrorFile(true);
      return;
    } else {
      setShowLoader(true);
      setShowMessage("Please wait...");
      let payload = {
        orderId: props.orderData._id,
        lot_number: props.number,
        lot_id:
          (props.orderData.shippingData[props.number - 1] &&
            props.orderData.shippingData[props.number - 1].lot_id) ||
          null,
        additionalComment: message,
        editForm2: initialForm2Save ? "" : editForm2,
      };
      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }
      for (let item in imageAttach) {
        formdata.append("media", imageAttach[item]);
      }
      formdata.append("lorry_receipt", fileAttach["Lorry Receipt"]);
      // formdata.append("gst_invoice", fileAttach["GST invoice"]);
      formdata.append("weightment_slip", fileAttach["Weighment slip"]);
      formdata.append(
        "material_test_certificate",
        fileAttach["Material Test Certificate"]
      );
      formdata.append("eway_bill", fileAttach["Ewaybill"]);
      // formdata.append("images", imageAttach);

      shippingModule(formdata, (status) => {
        if (status.status == "success") {
          setSubmitDisable(true);
          setShowLoader(false);
          addToast(status.message, {
            appearance: "success",
            autoDismissTimeout: 2500,
          });
          window.location.reload();
        } else {
          setShowLoader(false);
          addToast(status.message, {
            appearance: "error",
            autoDismissTimeout: 2500,
          });
        }
      })(dispatch);

      // dispatch(
      //   getShipping(formdata, (callback) => {
      //     props.load();
      //     setShowErrorName(false);
      //     setShowErrorNumber(false);
      //     setShowErrorImage(false);
      //     setShowErrorUr(false);
      //     setShowLoader(false);
      //   })
      // );
    }
  };

  const handleForm1Edit = () => {
    setEditForm1(!editForm1);
    setLotSize(
      props.orderData.shippingData[props.number - 1] &&
        props.orderData.shippingData[props.number - 1].lot_size
        ? props.orderData.shippingData[props.number - 1].lot_size
        : ""
    );
    setPickupDate(
      props.orderData.shippingData[props.number - 1] &&
        props.orderData.shippingData[props.number - 1].pickup_date
        ? props.orderData.shippingData[props.number - 1].pickup_date
        : ""
    );
    setDriverName(
      props.orderData.shippingData[props.number - 1] &&
        props.orderData.shippingData[props.number - 1].driver_name
        ? props.orderData.shippingData[props.number - 1].driver_name
        : ""
    );
    setDriverNumber(
      props.orderData.shippingData[props.number - 1] &&
        props.orderData.shippingData[props.number - 1].driver_number
        ? props.orderData.shippingData[props.number - 1].driver_number
        : ""
    );
    setVehicleNumber(
      props.orderData.shippingData[props.number - 1] &&
        props.orderData.shippingData[props.number - 1].vehicle_number
        ? props.orderData.shippingData[props.number - 1].vehicle_number
        : ""
    );
    setUrl(
      props.orderData.shippingData[props.number - 1] &&
        props.orderData.shippingData[props.number - 1].tracking_url
        ? props.orderData.shippingData[props.number - 1].tracking_url
        : ""
    );
  };

  const handleForm2Edit = () => {
    setEditForm2(!editForm2);
    setInitialForm2Save(false);
  };

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 2);
  const minDate = tomorrow.toISOString().split("T")[0];

  // Determine steps with previous steps activation
  let trackingStatus = props.orderData.shippingData[props.number - 1]
    ? props.orderData.shippingData[props.number - 1].tracking_status
    : "";

  const lot =
    props.orderData.shippingData &&
    props.orderData.shippingData[props.number - 1] &&
    props.orderData.shippingData[props.number - 1].lot_number
      ? props.orderData.shippingData[props.number - 1].lot_number
      : "";

  if (
    props.completeOrderdetail.received_shipping_status &&
    props.completeOrderdetail.received_shipping_status[lot - 1] &&
    props.completeOrderdetail.received_shipping_status[lot - 1].isReceived
  ) {
    trackingStatus = "Goods Received";
  }

  const steps = [
    { name: "Ready To Ship" },
    { name: "Goods Dispatched" },
    { name: "Goods Received" },
  ].map((step, index, allSteps) => {
    // Find the index of the current tracking status
    const activeIndex = allSteps.findIndex((s) => s.name === trackingStatus);

    return {
      ...step,
      isActive: index <= activeIndex, // Mark step active if it's at or before the current active step
    };
  });

  // Step 1: Set up state to control popup visibility
  const [isReturnPopupVisible, setIsReturnPopupVisible] = useState(false);
  const [lotReturnReason, setLotReturnReason] = useState("");

  const handleReturnLot = (e) => {
    e.preventDefault();

    if (lotReturnReason == "") {
      addToast("Please enter return reason", {
        appearance: "error",
        autoDismissTimeout: 2500,
      });
      return;
    }
    setShowLoader(true);
    let payload = {
      orderId: props.orderData._id,
      lot_number: props.number,
      returnReason: lotReturnReason,
    };
    let formdata = new FormData();
    for (let item in payload) {
      formdata.append(item, payload[item]);
    }
    returnLot(formdata, (status) => {
      if (status.status == "success") {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "success",
          autoDismissTimeout: 2500,
        });
        window.location.reload(false);
      } else {
        setShowLoader(false);
        addToast(status.message, {
          appearance: "error",
          autoDismissTimeout: 2500,
        });
      }
    })(dispatch);
  };

  const handleLotReturnReason = (e) => {
    setLotReturnReason(e.target.value);
  };

  return (
    <>
      {show && (
        <div className="shipping-module-wrp">
          {showLoader ? <Loader message={showMessage} /> : null}
          <div
            className={`remove-form-shipping-wrap ${
              props.number > 1 ? "have-border" : ""
            }`}
          >
            <h4 style={{ textWrap: "nowrap" }}>
              Lot{" "}
              {props.orderData.shippingData.length &&
              props.orderData.shippingData[props.number - 1] &&
              props.orderData.shippingData[props.number - 1].lot_number
                ? props.orderData.shippingData[props.number - 1].lot_number
                : props.number}
            </h4>
            <StepTimeline steps={steps} />
            {submitDisable ||
              (props.number > 1 &&
                !(
                  props.orderData.shippingData[props.number - 1] &&
                  props.orderData.shippingData[props.number - 1].lot_size
                ) && (
                  <button
                    className="remove-form-shipping"
                    onClick={handleRemoveSingle}
                  ></button>
                ))}
            {props.orderData.shippingData[props.number - 1] &&
              props.orderData.shippingData[props.number - 1].lot_size && (
                <span
                  style={{
                    display: "flex",
                    gap: "5px",
                    cursor: "pointer",
                    textWrap: "nowrap",
                  }}
                  onClick={handleForm1Edit}
                >
                  {" "}
                  <i
                    class={editForm1 ? "fas fa-times" : "fas fa-pencil"}
                    style={{ padding: "2px" }}
                  ></i>
                  {editForm1 ? "Cancel" : "Edit"}
                </span>
              )}
          </div>

          <div className="form-wrapper-new">
            {props.orderData.isShipping == false &&
            props.userType == "Seller" ? (
              <form>
                {/* <div className="full-form-block separate-form-shipping lot-date-wrapper"> */}
                <div className="full-form-block">
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Lot size <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter lot size"
                        className="form-control"
                        disabled={!editForm1}
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1]
                                .lot_size
                            : ""
                        }
                        onChange={(e) => handleLotSize(e)}
                      />
                    </div>
                    {showErrorLotSize ? (
                      <span className="error">Please enter lot size</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Pickup date <span className="astrisk-sign">*</span>
                      </label>
                      <div style={{ position: "relative" }}>
                        <input
                          type="date"
                          placeholder="Enter Pickup Date"
                          className="form-control"
                          value={
                            props.orderData.shippingData[props.number - 1]
                              ? props.orderData.shippingData[props.number - 1]
                                  .pickup_date
                              : pickupDate
                          }
                          disabled={!editForm1}
                          // value={pickupDate}
                          // min={minDate}
                          onChange={(e) => handlePickupDate(e)}
                        />
                      </div>
                    </div>
                    {showErrorDate ? (
                      <span className="error">Please enter pickup date</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="full-form-block">
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Driver name <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter driver name"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1]
                                .driver_name
                            : ""
                        }
                        disabled={!editForm1}
                        onChange={(e) => handleDriverName(e)}
                      />
                    </div>
                    {showErrorName ? (
                      <span className="error">Please enter driver name</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Driver number <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter driver number"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1]
                                .driver_number
                            : ""
                        }
                        disabled={!editForm1}
                        onChange={(e) => handleDriverNumber(e)}
                      />
                    </div>
                    {showErrorDriverNumber ? (
                      <span className="error">Please enter driver number</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                <div className="full-form-block">
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>
                        Vehicle number <span className="astrisk-sign">*</span>
                      </label>
                      <input
                        type="text"
                        placeholder="Enter vehicle number"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1]
                                .vehicle_number
                            : ""
                        }
                        disabled={!editForm1}
                        onChange={(e) => handleVehicleNumber(e)}
                      />
                    </div>
                    {showErrorNumber ? (
                      <span className="error">Please enter vehicle number</span>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-block-error-wrp form-block-new">
                    <div className="form-block">
                      <label>Tracking URL</label>
                      <input
                        type="url"
                        placeholder="Enter tracking URL"
                        className="form-control"
                        defaultValue={
                          props.orderData.shippingData[props.number - 1]
                            ? props.orderData.shippingData[props.number - 1]
                                .tracking_url
                            : ""
                        }
                        disabled={!editForm1}
                        onChange={(e) => setUrl(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-block">
                  <label>
                    Upload document <span className="astrisk-sign">*</span>
                  </label>
                  <div className="file-img-inputs progress-file-wrp">
                    {[1].map((item, index) => {
                      return !fileAttach[fileTypes[index]] ? (
                        <div>
                          {props.orderData.order_status !== "GoodsReceived" ? (
                            <div
                              className={`input-file-wrp-shipping ${
                                props.orderData.shippingData[
                                  props.number - 1
                                ] && !editForm1
                                  ? "disabled"
                                  : ""
                              }`}
                              key={item}
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "relative",
                              }}
                            >
                              <i class="fas fa-plus"></i>
                              <input
                                type="file"
                                className="file-in"
                                name="upload"
                                accept=".pdf"
                                onChange={(e) =>
                                  fileHandler(e, fileTypes[index])
                                }
                                disabled={
                                  props.orderData.shippingData[
                                    props.number - 1
                                  ] && !editForm1
                                }
                              />
                            </div>
                          ) : (
                            <a
                              href={
                                props.orderData.shippingData[props.number - 1][
                                  fileNames[index]
                                ]
                              }
                              target="_blank"
                              className="btn download-btn-wrp"
                              style={{
                                padding: "9px 23px",
                                color: "rgb(25, 135, 84)",
                              }}
                            >
                              Download
                            </a>
                          )}
                          {props &&
                            props.orderData.shippingData &&
                            props.orderData.shippingData[props.number - 1] &&
                            props.orderData.shippingData[props.number - 1][
                              fileNames[index]
                            ] && (
                              <a
                                href={
                                  props.orderData.shippingData[
                                    props.number - 1
                                  ][fileNames[index]]
                                }
                                target="_blank"
                                className="btn download-btn-wrp"
                                style={{
                                  padding: "10px 12px",
                                  color: "rgb(25, 135, 84)",
                                  marginTop: "15px",
                                  textWrap: "nowrap",
                                }}
                              >
                                {fileTypes[index]}
                                <i
                                  className="fas fa-download"
                                  style={{ marginLeft: "5px" }}
                                ></i>
                              </a>
                            )}
                          {props &&
                          props.orderData.shippingData &&
                          props.orderData.shippingData[props.number - 1] &&
                          props.orderData.shippingData[props.number - 1][
                            fileNames[index]
                          ] ? (
                            ""
                          ) : (
                            <h6>{fileTypes[index]}</h6>
                          )}
                        </div>
                      ) : (
                        <div>
                          <div
                            className="uploaded-img-view"
                            key={item}
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <i
                              style={{ fontSize: "28px" }}
                              className={
                                fileAttach[fileTypes[index]].type ===
                                "application/pdf"
                                  ? "fas fa-file-pdf green-icon-pdf"
                                  : "far fa-file"
                              }
                            ></i>
                            <input
                              type="file"
                              className="file-in after-upload-input"
                              name="upload"
                              accept=".pdf"
                              onChange={(e) => fileHandler(e, fileTypes[index])}
                            />
                            {/* <img className={} src={fileAttach[index].type==="application/pdf" ? "fas fa-file-pdf" : "far fa-file"} alt="view-img" /> */}
                          </div>
                          <h6 style={{ width: "100px" }}>{fileTypes[index]}</h6>
                        </div>
                      );
                    })}
                  </div>
                  {/* {img !== "" && (
                        <div className="uploaded-img-view" key={index}>
                        <img src={URL.createObjectURL(index)} alt="view-img" />
                      </div>
                    )} */}
                </div>

                {props.orderData.order_status !== "Completed" && (
                  <div
                    className="btn-wrapper-submit"
                    style={{ marginTop: "33px", justifyContent: "flex-end" }}
                  >
                    <button
                      className="btn"
                      type="submit"
                      onClick={handleSubmitInitial}
                      disabled={!editForm1}
                    >
                      Submit
                    </button>
                  </div>
                )}
                {/* </div> */}

                <div
                  className="full-form-block"
                  style={{
                    display: "block",
                    marginTop: "20px",
                    borderTop: "1px solid rgba(0,0,0,0.2)",
                    paddingTop: "20px",
                  }}
                >
                  <div
                    className="form-block form-block-new"
                    style={{ width: "100%" }}
                  >
                    <div
                      className="form-blockk-img-wrp"
                      style={{ marginBottom: "10px" }}
                    >
                      <div className="form-block">
                        <label
                          style={{
                            display: "flex",
                            gap: "5px",
                            justifyContent: "space-between",
                          }}
                        >
                          Upload document{" "}
                          <span
                            className="astrisk-sign"
                            style={{ display: "contents" }}
                          >
                            *
                          </span>
                          {props.orderData.shippingData[props.number - 1] &&
                            props.orderData.shippingData[props.number - 1]
                              .mediaImages && (
                              <span
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  cursor: "pointer",
                                }}
                                onClick={handleForm2Edit}
                              >
                                {" "}
                                <i
                                  class={
                                    editForm2 ? "fas fa-times" : "fas fa-pencil"
                                  }
                                  style={{ padding: "2px" }}
                                ></i>
                                {editForm2 ? "Cancel" : "Edit"}
                              </span>
                            )}
                        </label>
                        <div className="file-img-inputs progress-file-wrp">
                          {[1, 2, 3, 4, 5].map((item, index) => {
                            if (index === 0) {
                              return null;
                            }
                            return !fileAttach[fileTypes[index]] ? (
                              <div>
                                {props.orderData.order_status !==
                                "GoodsReceived" ? (
                                  <>
                                    <div
                                      className={`input-file-wrp-shipping ${
                                        !editForm2 ? "disabled" : ""
                                      }`}
                                      key={item}
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        position: "relative",
                                      }}
                                    >
                                      <i className="fas fa-plus"></i>
                                      <input
                                        type="file"
                                        className="file-in"
                                        name="upload"
                                        accept=".pdf"
                                        onChange={(e) =>
                                          fileHandler(e, fileTypes[index])
                                        }
                                        disabled={!editForm2}
                                      />
                                    </div>
                                    {props &&
                                      props.orderData.shippingData &&
                                      props.orderData.shippingData[
                                        props.number - 1
                                      ] &&
                                      props.orderData.shippingData[
                                        props.number - 1
                                      ][fileNames[index]] && (
                                        <a
                                          href={
                                            props.orderData.shippingData[
                                              props.number - 1
                                            ][fileNames[index]]
                                          }
                                          target="_blank"
                                          className="btn download-btn-wrp"
                                          style={{
                                            padding: "10px 12px",
                                            color: "rgb(25, 135, 84)",
                                            marginTop: "15px",
                                            textWrap: "nowrap",
                                            marginTop: "20px",
                                          }}
                                        >
                                          {fileTypes[index]}
                                          <i
                                            className="fas fa-download"
                                            style={{ marginLeft: "5px" }}
                                          ></i>
                                        </a>
                                      )}
                                  </>
                                ) : (
                                  <a
                                    href={
                                      props.orderData.shippingData[
                                        props.number - 1
                                      ][fileNames[index]]
                                    }
                                    target="_blank"
                                    className="btn download-btn-wrp"
                                    style={{
                                      padding: "9px 23px",
                                      color: "rgb(25, 135, 84)",
                                    }}
                                  >
                                    Download
                                  </a>
                                )}
                                {props &&
                                props.orderData.shippingData &&
                                props.orderData.shippingData[
                                  props.number - 1
                                ] &&
                                props.orderData.shippingData[props.number - 1][
                                  fileNames[index]
                                ] ? (
                                  ""
                                ) : (
                                  <h6>{fileTypes[index]}</h6>
                                )}
                              </div>
                            ) : (
                              <div>
                                <div
                                  className="uploaded-img-view"
                                  key={item}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                  }}
                                >
                                  <i
                                    style={{ fontSize: "28px" }}
                                    className={
                                      fileAttach[fileTypes[index]].type ===
                                      "application/pdf"
                                        ? "fas fa-file-pdf green-icon-pdf"
                                        : "far fa-file"
                                    }
                                  ></i>
                                  <input
                                    type="file"
                                    className="file-in after-upload-input"
                                    name="upload"
                                    accept=".pdf"
                                    disabled={!editForm2}
                                    onChange={(e) =>
                                      fileHandler(e, fileTypes[index])
                                    }
                                  />
                                  {/* <img className={} src={fileAttach[index].type==="application/pdf" ? "fas fa-file-pdf" : "far fa-file"} alt="view-img" /> */}
                                </div>
                                <h6 style={{ width: "100px" }}>
                                  {fileTypes[index]}
                                </h6>
                              </div>
                            );
                          })}
                        </div>
                        {/* {img !== "" && (
                        <div className="uploaded-img-view" key={index}>
                        <img src={URL.createObjectURL(index)} alt="view-img" />
                      </div>
                    )} */}
                      </div>
                      {showErrorFile ? (
                        <span className="error">Please upload documents</span>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="form-blockk-img-wrp form-main-block">
                      <div className="form-block">
                        <label>
                          Upload loading pictures{" "}
                        </label>
                        {props.orderData.shippingData[props.number - 1] &&
                        props.orderData.shippingData[props.number - 1]
                          .mediaImages &&
                        !editForm2 ? (
                          <div className="file-img-inputs progress-file-wrp">
                            {props.orderData.shippingData[
                              props.number - 1
                            ].mediaImages.map((item, index) => {
                              return (
                                <a
                                  href={item.mediaData}
                                  target={"_blank"}
                                  className="uploaded-img-view"
                                  key={index}
                                >
                                  <img src={item.mediaData} alt="view-img" />
                                </a>
                              );
                            })}
                          </div>
                        ) : (
                          <div className="file-img-inputs progress-file-wrp">
                            {[1, 2, 3, 4, 5].map((item, index) => {
                              return !imageAttach[index] ? (
                                <div
                                  className="input-file-wrp-shipping"
                                  key={item}
                                >
                                  <i class="fas fa-plus"></i>
                                  <input
                                    type="file"
                                    className="file-in"
                                    name="upload"
                                    accept=".png,.jpeg,.jpg"
                                    onChange={(e) => imgHandler(e, index)}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="uploaded-img-view"
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={URL.createObjectURL(
                                      imageAttach[index]
                                    )}
                                    alt="view-img"
                                  />
                                  <input
                                    type="file"
                                    className="file-in after-upload-input"
                                    name="upload"
                                    accept=".png,.jpeg,.jpg"
                                    onChange={(e) => imgHandler(e, index)}
                                    disabled={submitDisable}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {props.orderData.shippingData[props.number - 1] &&
                        props.orderData.shippingData[props.number - 1]
                        .mediaImages && 
                        !props.orderData.shippingData[props.number - 1]
                          .mediaImages.length && (<span>No pictures uploaded</span>)}
                      </div>
                    </div>
                  </div>
                  <div
                    className="form-block form-block-new"
                    style={{ width: "100%", marginTop: "20px" }}
                  >
                    <label>Additional notes</label>
                    <textarea
                      id="w3review"
                      placeholder="Enter additional notes"
                      rows="4"
                      cols="50"
                      className="shipping-text-area"
                      defaultValue={
                        props.orderData.shippingData[props.number - 1]
                          ? props.orderData.shippingData[props.number - 1]
                              .additionalComment
                          : ""
                      }
                      disabled={!editForm2}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {props.orderData &&
                  props.orderData.shippingData[props.number - 1] &&
                  props.orderData.shippingData[props.number - 1]
                    .returnReason ? (
                    <div>
                      <span style={{ fontWeight: "bold" }}>Return Reason</span>{" "}
                      - &nbsp;
                      {
                        props.orderData.shippingData[props.number - 1]
                          .returnReason
                      }
                    </div>
                  ) : (
                    <span></span>
                  )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      gap: "20px",
                    }}
                  >
                    {props.orderData.order_status !== "Completed" && (
                      <div
                        className="btn-wrapper-submit"
                        style={{
                          marginTop: "20px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          className="btn"
                          disabled={
                            props &&
                            props.orderData &&
                            props.orderData.shippingData[props.number - 1] &&
                            props.orderData.shippingData[props.number - 1]
                              .returnReason
                              ? true
                              : false
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setIsReturnPopupVisible(true);
                          }}
                        >
                          Return Lot
                        </button>
                      </div>
                    )}
                    {props.orderData.order_status !== "Completed" && (
                      <div
                        className="btn-wrapper-submit"
                        style={{
                          marginTop: "20px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <button
                          className="btn"
                          disabled={!editForm2}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            ) : props.orderData.isShipping == true &&
              (props.userType == "Seller" || props.userType == "Buyer") ? (
              <div className="form-output-wrapper">
                <div className="value-wrap">
                  <span className="name">vehicle Name : </span>
                  <span className="value">
                    {props.orderData.shippingData.vehicleName}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">vehicle Number : </span>
                  <span className="value">
                    {props.orderData.shippingData.vehicleNumber}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">Message : </span>
                  <span className="value">
                    {props.orderData.shippingData.message}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">Url : </span>
                  <span className="value">
                    {props.orderData.shippingData.url}
                  </span>
                </div>

                <div className="value-wrap">
                  <span className="name">Receipt : </span>
                  <span className="value">
                    <div className="reciept-block-img">
                      <img
                        src={
                          props &&
                          props.orderData &&
                          props.orderData.shippingData &&
                          props.orderData.shippingData.docs &&
                          props.orderData.shippingData.docs[0] &&
                          props.orderData.shippingData.docs[0].url
                        }
                        alt="receipt-img"
                      />
                    </div>
                  </span>
                </div>
              </div>
            ) : null}
          </div>
          {isReturnPopupVisible && (
            <div className="popup">
              <h1 style={{ fontSize: "30px" }}>Reason for return?</h1>
              <i
                className="fas fa-times"
                onClick={() => setIsReturnPopupVisible(false)}
                style={{
                  display: "flex",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  fontSize: "20px",
                  cursor: "pointer",
                }}
              ></i>
              <input
                type="text"
                placeholder="Enter Return reason"
                className="form-control"
                style={{ width: "70%", margin: "0 auto" }}
                onChange={(e) => handleLotReturnReason(e)}
              />
              <div
                className="btn-wrapper-submit"
                style={{ marginTop: "20px", justifyContent: "flex-end" }}
              >
                <button className="btn" type="submit" onClick={handleReturnLot}>
                  Return
                </button>
              </div>
            </div>
          )}
          <style jsx>{`
            .popup {
              position: fixed;
              top: 50%;
              left: 50%;
              background-color: white;
              width: 500px;
              height: 300px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              transform: translate(-50%, -50%);
              border-radius: 10px;
              z-index: 1000;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
            }
          `}</style>
        </div>
      )}
    </>
  );
};

export default Shipping;
