import React, { useState, useEffect } from "react";
import ScrollToTop from "../../../ScrollToTop";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import AddBrandPopup from "../AddBrandPopup";
import {
  loadProductForEdit,
  updateProduct,
  addProduct,
  getCity,
} from "./../../../store/actions/sellItem";
import { useToasts } from "react-toast-notifications";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { isEmpty } from "../../../utils/commonFunctions";
import UploadImg from "./UploadImg";
import Popup from "../Popup";
import AccVerifiedPopup from "../AccVerifiedPopup";
import { parse } from "query-string";
import { uploadFile } from "react-s3";
import Loader from "../../Loader";
import { getCountry } from "../../../store/actions/sellerRegisterationAction";
import Multiselect from "multiselect-react-dropdown";
import moment from "moment";
import { getProductData } from "../../../store/actions/sellerRegisterationAction";
import "react-datepicker/dist/react-datepicker.css";

const schema = yup.object().shape({
  title: yup
    .string()
    .required("Please enter product name")
    .min(2, "Product name must be at least 2 characters")
    .max(20, "Product name must be at most 20 characters"),
  product_quantity: yup.string().required("Please enter product quantity"),
  category: yup.string().required("Please select category"),
  subcategory: yup.string().required("Please select subcategory"),
  brand: yup.string().required("Please select brand"),
  hsn_code: yup.string().required("Please select HSN code"),
  gst: yup.string().required("Please select GST"),
  description: yup
    .string()
    .required("Please enter product description")
    .max(5009, "Description max length is 5000."),
  conditions: yup.string().required("Please select condition"),
  zip_code: yup
    .string()
    .required("Please enter pin code")
    .min(6, "Pin code must be 6 digit")
    .max(6, "Pin code must be 6 digit"),
  deliveryType: yup.string().required("Please select delievery type"),
  current_store_price: yup.string().required("Please enter product price"),
  addressSuggest: yup.string().required("Please select address"),
  shippingCost: yup
    .number()
    .integer("Please enter a whole number")
    .typeError("Please enter shipping cost")
    .required("Please enter shipping cost"),
});

const conditionOptions = [
  {
    head: "New",
    desc: "New with tags (NWT). Unopened packaging. Unused.",
    active: false,
  },
  {
    head: "Like new",
    desc: "New without tags (NWOT). No signs of wear. Unused.",
    active: false,
  },
  {
    head: "Good",
    desc: "Gently used. One / few minor flaws. Functional.",
    active: false,
  },
  {
    head: "Fair",
    desc: "Used, functional, multiple flaws / defects.",
    active: false,
  },
  {
    head: "Poor",
    desc: "Major flaws, may be damaged, for parts.",
    active: false,
  },
];

const S3_BUCKET = "inszn-ecom",
  REGION = "us-east-1",
  accessKeyId = "AKIARPELQCLSFEHBNS7K",
  secretAccessKey = "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";

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId,
  secretAccessKey,
};

const AddWireProduct = (props) => {
  let history = useHistory();
  let dispatch = useDispatch();
  let params = useParams();
  let location = useLocation();

  let categoriesList = useSelector(
    (store) => store.productListingInfo.categories
  );
  let productDetail = useSelector(
    (store) => store.sellItemInfo.allDetailForEdit
  );
  let userProfile = useSelector((store) => store.userProfile.userProfileBanner);
  let userAgent = navigator.userAgent;
  let browserName;

  if (userAgent.match(/chrome|chromium|crios/i)) {
    browserName = "chrome";
  } else if (userAgent.match(/firefox|fxios/i)) {
    browserName = "firefox";
  } else if (userAgent.match(/safari/i)) {
    browserName = "safari";
  } else if (userAgent.match(/opr\//i)) {
    browserName = "opera";
  } else if (userAgent.match(/edg/i)) {
    browserName = "edge";
  } else {
    browserName = "No browser detection";
  }

  const {
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });

  const [spinnerLoader, setSpinnerLoader] = useState(false);
  const [subcategories, setSubcategories] = useState([]);
  const [showbrandpopup, setShowbrandpopup] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showMessage, setShowMessage] = useState("");
  const { addToast } = useToasts();
  const [productList, setProductList] = useState([]);
  const [productCategory, setProductCategory] = useState([]);
  const [processList, setProcessList] = useState([]);
  const [deliveryFirst, setDeliveryFirst] = useState([]);
  const [paymentTypeList, setPaymentTypeList] = useState([]);
  const [paymentType, setPaymentType] = useState("");
  const [paymentTypeError, setPaymentTypeError] = useState(false);

  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState(false);
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [grade, setGrade] = useState("");
  const [gradeError, setGradeError] = useState("");
  const [process, setProcess] = useState("");
  const [processError, setProcessError] = useState("");
  const [weight, setWeight] = useState("");
  const [weightError, setWeightError] = useState(false);
  const [minOrderQty, setMinOrderQty] = useState("");
  const [minOrderErrror, setMinOrderErrror] = useState(false);
  const [minOrderHundredError, setMinOrderHundredError] = useState(false);
  const [proSize, setProdSize] = useState("");
  const [prodSizeError, setProdSizeError] = useState(false);
  const [sizeTol, setSizeTol] = useState("");
  const [sizeTolError, setSizeTolError] = useState(false);
  const [uploadImageData, setUploadImageData] = useState({});
  const [uploadImageDataError, setUploadImageDataError] = useState(false);
  const [productSpecificationError, setProductSpecificationError] =
    useState(false);
  const [specificationTol, setSpecificationTol] = useState("");
  const [specificationTolError, setSpecificationTolError] = useState(false);
  const [deliveryFirstValue, setDeliveryFirstValue] = useState("");
  const [deliveryFirstValueError, setDeliveryFirstValueError] = useState(false);
  const [deliverySecondValue, setDeliverySecondValue] = useState("");
  const [deliverySecondValueError, setDeliverySecondValueError] =
    useState(false);
  const [cityValueError, setCityValueError] = useState(false);

  const [deliveryWinFirst, setDeliveryWinFirst] = useState("");
  const [deliveryWinFirstError, setDeliveryWinFirstError] = useState(false);
  const [deliveryWinSecond, setDeliveryWinSecond] = useState("");
  const [deliveryWinSecondError, setDeliveryWinSecondError] = useState(false);
  const [validUntil, setValidUntil] = useState("");
  const [validUntilError, setValidUntilError] = useState(false);
  const [otherPayType, setOtherPayType] = useState("");
  const [otherPayTypeError, setOtherPayTypeError] = useState(false);
  const [price, setPrice] = useState("");
  const [priceError, setPriceError] = useState(false);
  const [floorPrice, setFloorPrice] = useState("");
  const [floorPriceError, setFloorPriceError] = useState(false);
  const [loadingChanges, setLoadingChanges] = useState("");
  const [loadingChangesError, setLoadingChangesError] = useState(false);
  const [insuranceCharges, setInsuranceCharges] = useState("");
  const [insuranceChargesError, setInsuranceChargesError] = useState("");
  const [stateDataArr, setStateData] = useState({});
  const [city, setCity] = useState("");

  /////////////////// Specification //////////////////
  const [siOne, setSiOne] = useState("");
  const [siTwo, setSiTwo] = useState("");
  const [pOne, setPOne] = useState("");
  const [pTwo, setPTwo] = useState("");
  const [moOne, setMoOne] = useState("");
  const [moTwo, setMoTwo] = useState("");

  const [mnOne, setMnOne] = useState("");
  const [mnTwo, setMnTwo] = useState("");
  const [sOne, setSOne] = useState("");
  const [sTwo, setSTwo] = useState("");
  const [aiOne, setAiOne] = useState("");
  const [aiTwo, setAiTwo] = useState("");

  const [cOne, setCOne] = useState("");
  const [cTwo, setCTwo] = useState("");
  const [crOne, setCrOne] = useState("");
  const [crTwo, setCrTwo] = useState("");
  const [mgOne, setMgOne] = useState("");
  const [mgTwo, setMgTwo] = useState("");
  const [feOne, setFeOne] = useState("");
  const [feTwo, setFeTwo] = useState("");
  const [caOne, setCaOne] = useState("");
  const [caTwo, setCaTwo] = useState("");
  const [byDefaultImage, setByDefaultImage] = useState(
    "http://localhost:3000/assets/images/metal-2.jpg"
  );

  const [optionsSelect, setOptionsSelect] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");
  const [cityData, setCityData] = useState([]);

  const [optionsSelectRegionRegion, setOptionsSelectRegionRegion] = useState(
    []
  );
  const [selectedValueRegion, setSelectedValueRegion] = useState("");
  const [getCityList, setCityList] = useState([]);
  const [setRegion, setSetRegion] = useState([]);
  const [regionCity, setRegionsCity] = useState("");
  const [oldImages, setOldImages] = useState([]);
  const [packaging, setPackaging] = useState([]);
  const [packagingError, setPackageError] = useState(false);
  const [editPackaging, setEditPackaging] = useState([]);

  const [productData, setProductData] = useState([]);

  const [edit, setEdit] = useState([]);
  const [remarks, setRemarks] = useState("");
  const [coilsCount, setCoilCount] = useState();
  const [coilWeight, setCoilWeight] = useState();

  const [state, setState] = useState({
    categories: "",
    subcategory: "",
    brand: "",
    product_tag: {},
    subchildcategory: "",
    hsn_code: "",
    gst: "",
    description: "",
    deliveryType: "",
    isShippingFree: false,
    current_store_price: "",
    sell_price: "",
    latLang: {},
    attrData: [],
    isSubmited: false,
    isClickDraft: false,
    prodShippingDetail: {},
    prodImgs: [],
    addressName: "",
  });

  useEffect(() => {
    dispatch(
      getProductData({ type: "Wire" }, (status) => {
        setProductData(status);
      })
    );
  }, []);

  const setLangLat = (val, addressName) => {
    setValue("addressSuggest", val.lat);
    clearErrors("addressSuggest");
    setState({ ...state, latLang: val, addressName: addressName });
  };

  const onSelect = (e) => {
    setSelectedValue(e);
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].id);
    }
    setSetRegion(array);
    setDeliverySecondValueError(false);
    getRegion_listHandler(array);
  };

  const getRegion_listHandler = (array) => {
    let selectArray = [];
    for (let b = 0; b < array.length; b++) {
      const result = cityData.filter((word) => word._id == array[b]);
      selectArray.push(result.length > 0 ? result[0].cityData : []);
    }
    let getCityList = [];
    for (let c = 0; c < selectArray.length; c++) {
      let gtLength = selectArray[c].length;
      for (let d = 0; d < selectArray[c].length; d++) {
        getCityList.push(selectArray[c][d]);
      }
    }

    let formateList = [];
    for (let e = 0; e < getCityList.length; e++) {
      formateList.push({
        name: getCityList[e].cityName,
        id: getCityList[e]._id,
      });
    }
    setOptionsSelectRegionRegion(formateList);
    return formateList;
  };

  const onSelectRegion = (e) => {
    // let array = [];
    // for (let a = 0; a < e.length; a++) {
    //     array.push(e[a].id);
    // }
    setRegionsCity(e.target.value);
    setCityValueError(false);
  };

  const onRemove = (e) => {
    let array = [];
    for (let a = 0; a < e.length; a++) {
      array.push(e[a].id);
    }

    setSetRegion(array);
    getRegion_listHandler(array);
  };

  const getCurrentDateTime = () => {
    return moment().format("YYYY-MM-DDTHH:mm");
  };

  useEffect(() => {
    const url = "http://localhost:3000/assets/images/metal-2.jpg";
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const arr = [];
    // fetch(url)
    //   .then((response) => response.blob())
    //   .then(
    //     (blob) =>
    //       new File([blob], `${fileName}`, {
    //         type: blob.type,
    //       })
    //   )
    //   .then((file) => {
    //     arr.push(file);
    //     if (params.id) {
    //     } else {
    //       setUploadImageData(arr);
    //     }
    //   });

    getCountry((callback) => {
      let newState = [...callback];
      setStateData(newState[0]);
    })(dispatch);
    let list = [
      { id: 13, name: "Wire Rod" },
      { id: 14, name: "H.B. Wire" },
    ];
    setProductList(list);

    let cList = [
      { id: "6304d4d848bf984c711e23b1", name: "Coil" },
      { id: "6304d69b48bf984c711e23d0", name: "Straightening" },
      { id: "6304d6ef48bf984c711e23ef", name: "Defective Coil" },
    ];

    let pList = [
      { id: 1, name: "jiggs" },
      { id: 2, name: "prime" },
    ];

    setProcessList(pList);

    setProductCategory(cList);

    let dFList = [
      { id: 1, name: "FOR" },
      { id: 2, name: "EXW" },
      //   { id: 3, name: "Local" },
      //   { id: 4, name: "Nation Wide" },
      //   { id: 4, name: "Inside State Only" },
    ];

    setDeliveryFirst(dFList);

    let dSList = [
      { id: 1, name: "Noida" },
      { id: 2, name: "Muzaffarnagar" },
    ];

    // setDeliverySecond(dSList)

    let paymentList = [
      { id: 1, name: "Advance Payment" },
      { id: 2, name: "Next Day" },
      { id: 3, name: "7 Day Credit" },
      { id: 4, name: "Other" },
    ];

    setPaymentTypeList(paymentList);

    setShowLoader(true);
    setShowMessage("Please wait...");
    // getAttribute({ categoryId: "60826c013c535579f4bbe1b4", subcategoryId: "6087d0050f56cb4d6bdb65b1" })(dispatch);
    setTimeout(() => setShowLoader(false), 2000);
    if (params.id) {
      setTimeout(
        () =>
          loadProductForEdit(params.id, parse(location.search).type)(dispatch),
        40
      );
    }
    getCity((result) => {
      setCityData(result.data);
      if (result.status == "success") {
        let arr = [];
        for (let a = 0; a < result.data.length; a++) {
          arr.push({ name: result.data[a].name, id: result.data[a]._id });
        }
        setOptionsSelect(arr);
      }
    })(dispatch);
  }, []);

  useEffect(() => {
    let editVar = [];
    if (productDetail.packaging) {
      let prodPack = productDetail.packaging.map((item) => {
        editVar = [...editVar, item];
      });
    }
    if (params.id) {
      if (!isEmpty(productDetail)) {
        setProductName(productDetail.productName);
        setGrade(productDetail.productGrade);
        setProcess(productDetail.process);
        setWeight(productDetail.productWeight);
        setCategory(productDetail.category);
        setMinOrderQty(productDetail.minOrderQty);
        setProdSize(productDetail.productSize);
        setSizeTol(productDetail.productSizeTolerance);
        setSpecificationTol(productDetail.specificationTolerance);
        setDeliveryFirstValue(productDetail.deliveryType);
        setEditPackaging(editVar);
        setPackaging(editVar);

        ////////////// For REgions ///////////////

        let arr1 = [];
        for (let a = 0; a < productDetail.region.length; a++) {
          let getSelValReal =
            optionsSelect.length > 0
              ? optionsSelect.filter(
                  (word) => word.id === productDetail.region[a]
                )
              : [{ name: null, id: null }];
          arr1.push(getSelValReal[0]);
        }
        //  let formDataIdRegion = [];
        //  for(let y = 0; y < arr1.length; y++){
        //     formDataIdRegion.push(arr1[y].)
        //  }
        setSetRegion(productDetail.region);
        setSelectedValue(arr1);
        setDeliverySecondValue(arr1);
        setRemarks(productDetail.remarks);
        setValidUntil(productDetail.expiryDate);
        setCoilCount(productDetail.coilCount);
        setCoilWeight(productDetail.coilWeight);

        ///////////// For City ///////////
        let REgionCity = getRegion_listHandler(productDetail.region);
        let arr2 = [];
        for (let a = 0; a < productDetail.city.length; a++) {
          let getSelValReal =
            REgionCity.length > 0
              ? REgionCity.filter((word) => word.id === productDetail.city[a])
              : [{ name: null, id: null }];
          arr2.push(getSelValReal[0]);
        }

        setRegionsCity(productDetail.city);
        setSelectedValueRegion(arr2);
        setDeliverySecondValue(arr2);

        let act = moment(productDetail.deliveryDateFrom).format("YYYY-MM-DD");
        let act2 = moment(productDetail.deliveryDateTo).format("YYYY-MM-DD");

        setDeliveryWinFirst(act);
        setDeliveryWinSecond(act2);
        setPaymentType(productDetail.paymentType);
        // setPackaging([]);
        setPrice(productDetail.productPrice.$numberDecimal);
        setFloorPrice(productDetail.floorPrice.$numberDecimal);
        if (productDetail.loadingCharge) {
          setLoadingChanges(productDetail.loadingCharge.$numberDecimal);
        }
        if (productDetail.insuranceCharge) {
          setInsuranceCharges(productDetail.insuranceCharge.$numberDecimal);
        }

        let originalIMages = [];
        for (let n = 0; n < productDetail.Image.length; n++) {
          originalIMages.push(productDetail.Image[n].original);
        }
        setOldImages(originalIMages);

        setState({
          prodImgs: productDetail.Image,
        });
        setOtherPayType(productDetail.otherPayType);

        const object = productDetail.spec;

        for (const property in object) {
          let value = object[property];
          let seprated = value.split("% ");
          let first = seprated[0];
          let second = seprated[1];

          if (property == "al") {
            setAiOne(first);
            setAiTwo(second);
          } else if (property == "c") {
            setCOne(first);
            setCTwo(second);
          } else if (property == "cr") {
            setCrOne(first);
            setCrTwo(second);
          } else if (property == "mg") {
            setMgOne(first);
            setMgTwo(second);
          } else if (property == "mn") {
            setMnOne(first);
            setMnTwo(second);
          } else if (property == "mo") {
            setMoOne(first);
            setMoTwo(second);
          } else if (property == "p") {
            setPOne(first);
            setPTwo(second);
          } else if (property == "s") {
            setSOne(first);
            setSTwo(second);
          } else if (property == "si") {
            setSiOne(first);
            setSiTwo(second);
          } else if (property == "fe") {
            setFeOne(first);
            setFeTwo(second);
          } else if (property == "ca") {
            setCaOne(first);
            setCaTwo(second);
          }
        }
      }
    } else {
      setProductName("");
      setGrade("");
      setProcess("");
      setWeight("");
      setCategory("");
      setMinOrderQty("");
      setProdSize("");
      setSizeTol("");
      setSpecificationTol("");
      setDeliveryFirstValue("");
      setEditPackaging([]);
      setPackaging([]);

      // ////////////// For REgions ///////////////

      // let arr1 = [];
      // for (let a = 0; a < productDetail.region.length; a++) {
      //     let getSelValReal = optionsSelect.length > 0 ?
      //         optionsSelect.filter(word => word.id === productDetail.region[a])
      //         : [{ name: null, id: null }];
      //     arr1.push(getSelValReal[0]);
      // }
      // //  let formDataIdRegion = [];
      // //  for(let y = 0; y < arr1.length; y++){
      // //     formDataIdRegion.push(arr1[y].)
      // //  }
      setSetRegion([]);
      setSelectedValue("");
      setDeliverySecondValue("");

      // ///////////// For City ///////////
      // let REgionCity = getRegion_listHandler(productDetail.region);
      // let arr2 = [];
      // for (let a = 0; a < productDetail.city.length; a++) {
      //     let getSelValReal = REgionCity.length > 0 ?
      //         REgionCity.filter(word => word.id === productDetail.city[a])
      //         : [{ name: null, id: null }];
      //     arr2.push(getSelValReal[0]);
      // }

      setRegionsCity("");
      setSelectedValueRegion("");
      setDeliverySecondValue("");

      // let act = moment(productDetail.deliveryDateFrom).format('YYYY-MM-DD');
      // let act2 = moment(productDetail.deliveryDateTo).format('YYYY-MM-DD');

      setDeliveryWinFirst("");
      setDeliveryWinSecond("");
      setPaymentType("");
      setPrice("");
      setFloorPrice("");
      setLoadingChanges("");
      setInsuranceCharges("");

      // let originalIMages = [];
      // for (let n = 0; n < productDetail.Image.length; n++) {
      //     originalIMages.push(productDetail.Image[n].original)
      // }
      setOldImages([]);

      // setState({
      //     prodImgs: productDetail.Image
      // })
      setOtherPayType("");

      // const object = productDetail.spec;

      // for (const property in object) {
      //     let value = object[property];
      //     let seprated = value.split("% ");
      //     let first = seprated[0];
      //     let second = seprated[1];

      setAiOne("");
      setAiTwo("");
      setCOne("");
      setCTwo("");
      setCrOne("");
      setCrTwo("");
      setMgOne("");
      setMgTwo("");
      setMnOne("");
      setMnTwo("");
      setMoOne("");
      setMoTwo("");
      setPOne("");
      setPTwo("");
      setSOne("");
      setSTwo("");
      setSiOne("");
      setSiTwo("");
      setFeOne("");
      setFeTwo("");
      setCaOne("");
      setCaTwo("");
      //     }
      // }
    }
  }, [productDetail, params]);

  useEffect(() => {
    if (!params.id) {
      setSubcategories([]);
      setState({
        ...state,
        categories: "",
        subcategory: "",
        brand: "",
        product_tag: {},
        subchildcategory: "",
        hsn_code: "",
        gst: "",
        description: "",
        deliveryType: "",
        isShippingFree: false,
        current_store_price: "",
        sell_price: "",
        latLang: {},
        attrData: [],
        isSubmited: false,
        isClickDraft: false,
        prodShippingDetail: {},
        prodImgs: [],
        addressName: "",
      });
      setValue("title", "");
      setValue("product_quantity", "");
      setValue("category", "");
      setValue("subcategory", "");
      setValue("brand", "");
      setValue("hsn_code", "");
      setValue("gst", "");
      setValue("description", "");

      setValue("conditions", "");
      setValue("zip_code", "");
      setValue("deliveryType", "");
      setValue("current_store_price", "");
      setValue("addressSuggest", "");
      setValue("shippingCost", "");
      setValue("sell_price", "");

      for (let index = 0; index < [1, 2, 3].length; index++) {
        let element = document.getElementById("tags_" + index);
        if (element) {
          element.value = "";
        }
      }
      // setTimeout(() => document.querySelector('.location-search-input').value = "", 1000)
    }
  }, [location]);

  const setSelectImgHandler = (val) => {};

  function getPromise(prodImgs) {
    return new Promise(async (resolve, reject) => {
      uploadFile(prodImgs, config)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          resolve(err);
        });
    });
  }

  function resolveThisFirst() {
    let newArr = [];
    for (let i = 0; i < state.prodImgs.length; i++) {
      //find is file or not
      if (state.prodImgs[i].type) {
        newArr.push(getPromise(state.prodImgs[i]));
      }
    }

    return Promise.all(newArr);
  }

  const setProdImgs = (val) => {
    let binaryData = [];
    let originalIMages = [];
    for (let x = 0; x < val.length; x++) {
      if ("original" in val[x]) {
        originalIMages.push(val[x].original);
        setOldImages(originalIMages);
      } else {
        binaryData.push(val[x]);
      }
    }
    setUploadImageData(binaryData);

    setUploadImageDataError(false);
    setState({ ...state, prodImgs: val });
  };

  ///////////////////////////////////////////////////////// New Form /////////////////////////////////////////////////////
  const productNameHandler = (e) => {
    setProductName(e.target.value);
    setProductNameError(false);
  };

  const productCategoryHandler = (e) => {
    setCategory(e.target.value);
    setCategoryError(false);
  };

  const gradeHandle = (e) => {
    setGrade(e.target.value);
    setGradeError(false);
  };

  const processHandler = (e) => {
    if (e.target.value) {
      setProcess(e.target.value);
    }
    setProcessError(false);
  };
  const deliveryFirstHandler = (e) => {
    setDeliveryFirstValue(e.target.value);
    setDeliveryFirstValueError(false);
  };

  const paymentTypeHandler = (e) => {
    setPaymentType(e.target.value);
    setPaymentTypeError(false);
  };
  const weightHandle = (e) => {
    var regex = /^[0-9-+()]*$/;
    if (regex.test(e.target.value)) {
      setWeight(e.target.value);
      setWeightError(false);
    }
  };
  const minOrderHandle = (e) => {
    var regex = /^[0-9-+()]*$/;
    if (regex.test(e.target.value)) {
      setMinOrderQty(e.target.value);
      setMinOrderErrror(false);
      setMinOrderHundredError(false);
    }
  };
  const prodSizeHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setProdSize(e.target.value);
      setProdSizeError(false);
    }
  };
  const sizeTolHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setSizeTol(e.target.value);
      setSizeTolError(false);
    }
  };

  const specTolHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setSpecificationTol(e.target.value);
      setSpecificationTolError(false);
    }
  };
  const deliveryWinFirstHandle = (e) => {
    setDeliveryWinFirst(e.target.value);
    setDeliveryWinFirstError(false);
  };
  const deliveryWinSEcondHandle = (e) => {
    setDeliveryWinSecond(e.target.value);
    setDeliveryWinSecondError(false);
  };

  const validUntilHandle = (e) => {
    setValidUntil(e.target.value);
    setValidUntilError(false);
  };

  const otherPayTypeHandle = (e) => {
    setOtherPayType(e.target.value);
    setOtherPayTypeError(false);
  };
  const priceHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setPrice(e.target.value);
      setPriceError(false);
    }
  };
  const floorPriceHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setFloorPrice(e.target.value);
      setFloorPriceError(false);
    }
  };
  const loadingChangesHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setLoadingChanges(e.target.value);
      setLoadingChangesError(false);
    }
  };
  const insuranceChargesHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value)) {
      setInsuranceCharges(e.target.value);
      setInsuranceChargesError(false);
    }
  };

  const siOneHandle = (e) => {
    setSiOne(e.target.value);
    setProductSpecificationError(false);
  };
  const siTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setSiTwo(e.target.value);
  };

  const pOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    const value = e.target.value;

    if (regex.test(value) || value === "" || pTwo === "N/A") {
      setProductSpecificationError(false);
      setPOne(value);
    }
  };

  const pTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setPTwo(e.target.value);
  };
  const moOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || moTwo == "N/A") {
      setProductSpecificationError(false);
      setMoOne(e.target.value);
    }
  };
  const moTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setMoTwo(e.target.value);
  };
  const mnOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || mnTwo == "N/A") {
      setProductSpecificationError(false);
      setMnOne(e.target.value);
    }
  };
  const mnTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setMnTwo(e.target.value);
  };
  const sOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || sTwo == "N/A") {
      setProductSpecificationError(false);
      setSOne(e.target.value);
    }
  };
  const sTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setSTwo(e.target.value);
  };
  const aiOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || aiTwo == "N/A") {
      setProductSpecificationError(false);
      setAiOne(e.target.value);
    }
  };
  const aiTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setAiTwo(e.target.value);
  };
  const cOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || cTwo == "N/A") {
      setProductSpecificationError(false);
      setCOne(e.target.value);
    }
  };
  const cTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setCTwo(e.target.value);
  };
  const crOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || crTwo == "N/A") {
      setProductSpecificationError(false);
      setCrOne(e.target.value);
    }
  };
  const crTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setCrTwo(e.target.value);
  };
  const mgOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || mgTwo == "N/A") {
      setProductSpecificationError(false);
      setMgOne(e.target.value);
    }
  };

  const mgTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setMgTwo(e.target.value);
  };

  const feOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || feTwo == "N/A") {
      setProductSpecificationError(false);
      setFeOne(e.target.value);
    }
  };

  const feTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setFeTwo(e.target.value);
  };

  const caOneHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    if (regex.test(e.target.value) || caTwo == "N/A") {
      setProductSpecificationError(false);
      setCaOne(e.target.value);
    }
  };

  const caTwoHandle = (e) => {
    var regex = /^([0-9]+(\.[0-9]+)?(-[0-9]+(\.[0-9]+)?)?|[()+\/\W|_])*$/;
    setProductSpecificationError(false);
    setCaTwo(e.target.value);
  };

  const handleJute = (e) => {
    if (e.target.checked) {
      let data = [...packaging, e.target.value];
      setPackaging(data);
      setPackageError(false);
    } else {
      let filteredData = packaging.filter((item) => item !== e.target.value);
      setPackaging(filteredData);
    }
  };

  const handleJute50 = (e) => {
    if (e.target.checked) {
      let data = [...packaging, e.target.value];
      setPackaging(data);
      setPackageError(false);
    } else {
      let filteredData = packaging.filter((item) => item !== e.target.value);
      setPackaging(filteredData);
    }
  };

  const handleJumbo = (e) => {
    if (e.target.checked) {
      let data = [...packaging, e.target.value];
      setPackaging(data);
      setPackageError(false);
    } else {
      let filteredData = packaging.filter((item) => item !== e.target.value);
      setPackaging(filteredData);
    }
  };

  function isValid() {
    let payloadProdImgs = [];
    state.prodImgs.forEach((item) => {
      if (item._id) {
        payloadProdImgs = [...payloadProdImgs, item];
      }
    });
    if (productName == "") {
      setProductNameError(true);
      document.getElementById("productName").focus();
    } else if (category == "") {
      setCategoryError(true);
      document.getElementById("category").focus();
    } else if ((category == "chips" || category == "fines") && process == "") {
      setProcessError(true);
      document.getElementById("jiggs").focus();
    } else if (grade == "") {
      setGradeError(true);
      document.getElementById("grade").focus();
    }
    // else if (weight == "") {
    //   setWeightError(true);
    //   document.getElementById("productWeight").focus();
    // }
    // else if (minOrderQty == "") {
    //   setMinOrderErrror(true);
    //   document.getElementById("minOrder").focus();
    // }
    else if (minOrderQty > 100) {
      setMinOrderHundredError(true);
      document.getElementById("minOrder").focus();
    } else if (proSize == "") {
      setProdSizeError(true);
      document.getElementById("productSize").focus();
    }
    // else if (sizeTol == "") {
    //   setSizeTolError(true);
    //   document.getElementById("sizeTolerance").focus();
    // }
    else if (
      Object.keys(uploadImageData).length <= 0 &&
      payloadProdImgs.length == 0
    ) {
      setUploadImageDataError(true);
    } else if (
      siTwo == "" &&
      pTwo == "" &&
      moTwo == "" &&
      mnTwo == "" &&
      sTwo == "" &&
      aiTwo == "" &&
      cTwo == "" &&
      crTwo == "" &&
      mgTwo == "" &&
      feTwo == "" &&
      caTwo == ""
    ) {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (siTwo !== "" && siOne == "" && siTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (pTwo !== "" && pOne == "" && pTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (moTwo !== "" && moOne == "" && moTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (mnTwo !== "" && mnOne == "" && mnTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (sTwo !== "" && sOne == "" && sTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (aiTwo !== "" && aiOne == "" && aiTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (cTwo !== "" && cOne == "" && cTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (crTwo !== "" && crOne == "" && crTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (mgTwo !== "" && mgOne == "" && mgTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (feTwo !== "" && feOne == "" && feTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    } else if (caTwo !== "" && caOne == "" && caTwo !== "N/A") {
      setProductSpecificationError(true);
      document.getElementById("specTol").focus();
    }
    // } else if (
    //   siOne == "" &&
    //   pOne == "" &&
    //   moOne == "" &&
    //   mnOne == "" &&
    //   sOne == "" &&
    //   aiOne == "" &&
    //   cOne == "" &&
    //   crOne == "" &&
    //   mgOne == "" &&
    //   feOne == "" &&
    //   caOne == ""
    // ) {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (siOne !== "" && siTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (pOne !== "" && pTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (moOne !== "" && moTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (mnOne !== "" && mnTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (sOne !== "" && sTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (aiOne !== "" && aiTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (cOne !== "" && cTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (crOne !== "" && crTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (mgOne !== "" && mgTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (feOne !== "" && feTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    // } else if (caOne !== "" && caTwo == "") {
    //   setProductSpecificationError(true);
    //   document.getElementById("specTol").focus();
    //}
    // else if (specificationTol == "") {
    //   setSpecificationTolError(true);
    //   document.getElementById("specTol").focus();
    // }
    else if (deliveryFirstValue == "") {
      setDeliveryFirstValueError(true);
      document.getElementById("deliveryType").focus();
    } else if (setRegion.length == 0) {
      setDeliverySecondValueError(true);
      document.getElementById("deliveryType").focus();
    } else if (regionCity == "") {
      setCityValueError(true);
      document.getElementById("deliveryType").focus();
    }
    // else if (deliveryWinFirst == "") {
    //   setDeliveryWinFirstError(true);
    //   document.getElementById("startDate").focus();
    // }
    // else if (deliveryWinSecond == "") {
    //   setDeliveryWinSecondError(true);
    //   document.getElementById("endDate").focus();
    // }
    else if (paymentType == "") {
      setPaymentTypeError(true);
      document.getElementById("paymentType").focus();
    }
    // else if (packaging.length == 0) {
    //   setPackageError(true);
    //   document.getElementById("paymentType").focus();
    // }
    else if (paymentType == "Other" && otherPayType == "") {
      setOtherPayTypeError(true);
      document.getElementById("otherPayment").focus();
    }
    // else if (price == "") {
    //   setPriceError(true);
    //   document.getElementById("price").focus();
    // }
    // else if (floorPrice == "") {
    //   setFloorPriceError(true);
    //   document.getElementById("floorPrice").focus();
    // }
    // }

    // }
    else {
      return true;
    }
  }
  const submitHandle = (e) => {
    let payloadProdImgs = [];
    state.prodImgs.forEach((item) => {
      if (item._id) {
        payloadProdImgs = [...payloadProdImgs, item];
      }
    });
    e.preventDefault();
    if (isValid()) {
      setShowLoader(true);
      setShowMessage("Please wait...");
      const payload = {
        productName: productName,
        productGrade: grade,
        process: category == "6304d4d848bf984c711e22b1" ? "" : process,
        productWeight: weight,
        category: category,
        minOrderQty: minOrderQty,
        productSize: proSize,
        productSizeTolerance: sizeTol,
        specificationTolerance: specificationTol,
        deliveryType: deliveryFirstValue,
        exwCity: deliverySecondValue,
        deliveryDateFrom: deliveryWinFirst,
        deliveryDateTo: deliveryWinSecond,
        paymentType: paymentType,
        productPrice: price,
        floorPrice: floorPrice,
        loadingCharge:
          loadingChanges && loadingChanges.length ? loadingChanges : 0,
        insuranceCharge:
          insuranceCharges && insuranceCharges.length ? insuranceCharges : 0,
        otherPayType: otherPayType,
        packaging: packaging,
        si: siOne ? siOne + "% " + siTwo : "N/A",
        p: pOne ? pOne + "% " + pTwo : "N/A",
        mo: moOne ? moOne + "% " + moTwo : "N/A",
        mn: mnOne ? mnOne + "% " + mnTwo : "N/A",
        s: sOne ? sOne + "% " + sTwo : "N/A",
        al: aiOne ? aiOne + "% " + aiTwo : "N/A",
        c: cOne ? cOne + "% " + cTwo : "N/A",
        cr: crOne ? crOne + "% " + crTwo : "N/A",
        mg: mgOne ? mgOne + "% " + mgTwo : "N/A",
        fe: feOne ? feOne + "% " + feTwo : "N/A",
        ca: caOne ? caOne + "% " + caTwo : "N/A",
        region: setRegion,
        city: regionCity,
        oldImages: JSON.stringify(payloadProdImgs),
        remarks,
        expiryDate: validUntil,
        coilsCount,
        coilWeight,
        // imageData:uploadImageData
      };
      // return;
      let formdata = new FormData();
      for (let item in payload) {
        formdata.append(item, payload[item]);
      }

      for (let item in uploadImageData) {
        formdata.append("imageData", uploadImageData[item]);
      }

      if (params.id) {
        formdata.append("productId", params.id);
        // formdata.append("oldImages", JSON.stringify(state.prodImgs));

        updateProduct(formdata, params.id, (status) => {
          if (status) {
            setShowLoader(false);
            setState({ ...state, isSubmited: true });
          } else {
            setShowLoader(false);
            addToast("Failed to submit", {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          }
        })(dispatch);
      } else {
        addProduct(formdata, (status) => {
          if (status.status == "success") {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "success",
              autoDismissTimeout: 2500,
            });
            setTimeout(() => {
              props.history.push("/");
            }, 1000);
          } else {
            setShowLoader(false);
            addToast(status.message, {
              appearance: "error",
              autoDismissTimeout: 2500,
            });
          }
        })(dispatch);
      }
    }
  };

  const redirectPage = () => {
    window.location = "/profile/my-listing/active";
  };

  if(userProfile && userProfile.userdata && userProfile.userdata.create_wire_listing_access == false){
    window.location.href = "/";
  }


  return (
    <div className="sell-item sell-item-content sell-product-wrapper seller-add-product">
      {showLoader ? <Loader message={showMessage} /> : null}
      {state.isSubmited && (
        <Popup
          // closePopup={closePopup}
          submitFun={redirectPage}
          content={
            params.id
              ? "Your product has been updated successfully"
              : "Your product has been uploaded successfully"
          }
          successTxt={"Go to listing page"}
          type={"submit"}
        />
      )}
      {state.isClickDraft && (
        <Popup
          // closePopup={closePopup}
          submitFun={() => history.push("/profile/my-listing/draft")}
          content={"Your product has been Saved."}
          successTxt={"Go to Draft page"}
          type={"submit"}
        />
      )}
      <div className={spinnerLoader ? "spinner-time-div" : ""}>
        {!isEmpty(userProfile) &&
        userProfile.userdata &&
        userProfile.userdata.userType === "Seller" ? (
          <form onSubmit={(e) => submitHandle(e)}>
            <div className="sell-image"></div>
            <div className="menu-item-container">
              <div className="navigation-screen edit-product-screen">
                <div className="add-product-wrapper">
                  <ScrollToTop />
                  {showbrandpopup && (
                    <AddBrandPopup
                      closePopup={setShowbrandpopup}
                      setShowbrandpopup={setShowbrandpopup}
                    />
                  )}
                  <h5> TELL US WHAT YOU ARE SELLING?</h5>
                  <div className="product-form">
                    <div className="slct-optns select-wrapper">
                      <div className="form-block-wrap">
                        <div className="enter-mobile-label">
                          Product Name <span className="highlighted">*</span>
                        </div>
                        <div className="select-main-wrapper">
                          <select
                            onChange={(e) => productNameHandler(e)}
                            id="productName"
                          >
                            <option value={""}>Select</option>
                            {productData.map((item) => (
                              <option
                                value={item.name}
                                selected={
                                  productName == item.name ? true : false
                                }
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="icon-arrow-down"></i>
                        </div>
                        {productNameError ? (
                          <span className="error">
                            Please select product name
                          </span>
                        ) : null}
                      </div>
                      <div className="form-block-wrap">
                        <div className="enter-mobile-label">
                          Product Category{" "}
                          <span className="highlighted">*</span>
                        </div>
                        <div className="select-main-wrapper">
                          <select
                            onChange={(e) => productCategoryHandler(e)}
                            id="category"
                          >
                            <option disabled={true} selected={true}>
                              Select
                            </option>
                            {productCategory.map((item) => (
                              <option
                                value={item.id}
                                selected={category == item.id ? true : null}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                          <i className="icon-arrow-down"></i>
                        </div>
                        {categoryError ? (
                          <span className="error">
                            Please select product category
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="dynamic-select-wrap">
                      {category == "6304d69b48bf984c711e22d0" ||
                      category == "6304d6ef48bf984c711e22ef" ? (
                        <div className="form-block-wrap">
                          {/* <div className="enter-mobile-label">Process <span className="highlighted">*</span></div> */}
                          <div className="select-main-wrapper">
                            <select
                              onChange={(e) => processHandler(e)}
                              id="jiggs"
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              {processList.map((item) => (
                                <option
                                  value={item._id}
                                  selected={process == item.name ? true : false}
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <i className="icon-arrow-down"></i>
                          </div>
                          {processError ? (
                            <span className="error">
                              Please select jiggs or prime
                            </span>
                          ) : null}
                        </div>
                      ) : null}
                    </div>
                    <div className="slct-optns select-wrapper">
                      <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                          Product Grade<span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            className={"input-url"}
                            type="text"
                            name="product_ta"
                            placeholder="e.g. 5.5"
                            onChange={(e) => gradeHandle(e)}
                            maxLength={25}
                            id="grade"
                            value={grade}
                          />
                        </div>
                        {gradeError ? (
                          <span className="error">
                            Please enter product grade
                          </span>
                        ) : null}
                      </div>
                      <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                          Available Qty (mt){" "}
                          {/* <span className="highlighted">*</span> */}
                        </label>
                        <div className="input-wrap">
                          <input
                            className={"input-url"}
                            type="text"
                            name="product_tag"
                            placeholder="e.g. 200 MT"
                            onChange={(e) => weightHandle(e)}
                            maxLength={25}
                            id="productWeight"
                            value={weight}
                          />
                        </div>
                        {weightError ? (
                          <span className="error">Please enter quantity</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="slct-optns select-wrapper">
                      <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                          Price (INR/mt)
                        </label>
                        <div className="input-wrap">
                          <input
                            className={"input-url"}
                            type="text"
                            name="product_tag"
                            placeholder="e.g. INR 25000"
                            onChange={(e) => priceHandle(e)}
                            maxLength={25}
                            id="price"
                            value={price}
                          />
                        </div>
                        {priceError ? (
                          <span className="error">Please select price</span>
                        ) : null}
                      </div>

                      {/* <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                          Floor Price (INR/mt)
                        </label>
                        <div className="input-wrap">
                          <input
                            className={"input-url"}
                            type="text"
                            name="product_tag"
                            placeholder="e.g. INR 250"
                            onChange={(e) => floorPriceHandle(e)}
                            maxLength={25}
                            id="floorPrice"
                            value={floorPrice}
                          />
                        </div>
                        {floorPriceError ? (
                          <span className="error">
                            Please enter floor price
                          </span>
                        ) : null}
                      </div> */}
                    </div>
                    <div className="delivery-window">
                      <div className="enter-mobile-label">
                        Delivery Window
                        {/* <span className="highlighted">*</span> */}
                      </div>
                      <div className="date-picker-wrapper">
                        <div className="form-block-wrap">
                          <div className="input-wrap">
                            <input
                              type="date"
                              className="date-picker-input"
                              onChange={(e) => deliveryWinFirstHandle(e)}
                              id="startDate"
                              value={deliveryWinFirst}
                              pattern="\d{4}-\d{2}-\d{2}"
                            />
                            {browserName == "chrome" ? (
                              <i class="far fa-calendar-alt"></i>
                            ) : null}
                          </div>
                          {deliveryWinFirstError ? (
                            <div className="error">
                              Please select start date
                            </div>
                          ) : null}
                        </div>
                        <div className="form-block-wrap">
                          <div className="input-wrap">
                            <input
                              type="date"
                              className="date-picker-input"
                              onChange={(e) => deliveryWinSEcondHandle(e)}
                              id="endDate"
                              value={deliveryWinSecond}
                              pattern="\d{4}-\d{2}-\d{2}"
                            />
                            {browserName == "chrome" ? (
                              <i class="far fa-calendar-alt"></i>
                            ) : null}
                          </div>
                          {deliveryWinSecondError ? (
                            <div className="error">Please select end date</div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="delivery-window">
                      <div className="enter-mobile-label">
                        Valid until
                        {/* <span className="highlighted">*</span> */}
                      </div>
                      <div className="date-picker-wrapper">
                        <div className="form-block-wrap">
                          <div className="input-wrap">
                            <input
                              type="datetime-local"
                              className="date-picker-input"
                              onChange={(e) => validUntilHandle(e)}
                              id="validUntilDate"
                              value={validUntil}
                              pattern="\d{4}-\d{2}-\d{2}"
                              min={getCurrentDateTime()} // Set min to current date and time
                              max="9999-12-31T23:59"
                            />
                            {/* {browserName == "chrome" ? (
                              <i class="far fa-calendar-alt"></i>
                            ) : null} */}
                          </div>
                          {validUntilError ? (
                            <div className="error">
                              Please select valid until
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="slct-optns select-wrapper">
                      <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                          Minimum Order QTY (mt){" "}
                          <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            className={"input-url"}
                            type="text"
                            name="product_tag"
                            placeholder="e.g. 200 MT"
                            onChange={(e) => minOrderHandle(e)}
                            maxLength={25}
                            id="minOrder"
                            value={minOrderQty}
                          />
                        </div>
                        {minOrderErrror ? (
                          <span className="error">Please enter min order</span>
                        ) : null}
                        {minOrderHundredError ? (
                          <span className="error">
                            You cannot enter min order more than 100
                          </span>
                        ) : null}
                      </div>

                      <div className="form-block-wrap">
                        <label className="enter-mobile-label">
                          Product Size (mm){" "}
                          <span className="highlighted">*</span>
                        </label>
                        <div className="input-wrap">
                          <input
                            className={"input-url"}
                            type="text"
                            name="product_tag"
                            placeholder="e.g. 5.5 mm"
                            onChange={(e) => prodSizeHandle(e)}
                            maxLength={25}
                            id="productSize"
                            value={proSize}
                          />
                        </div>
                        {prodSizeError ? (
                          <span className="error">
                            Please enter product size
                          </span>
                        ) : null}
                      </div>
                    </div>

                    {/* <div className="form-block-wrap">
                      <label className="enter-mobile-label">
                        Size Tolerance (±){" "}
                        <span className="highlighted">*</span>
                      </label>
                      <div className="input-wrap">
                        <input
                          className={"input-url"}
                          type="text"
                          name="product_tag"
                          placeholder="e.g. 10 %"
                          onChange={(e) => sizeTolHandle(e)}
                          maxLength={25}
                          id="sizeTolerance"
                          value={sizeTol}
                        />
                      </div>
                      {sizeTolError ? (
                        <span className="error">
                          Please enter size tolerance
                        </span>
                      ) : null}
                    </div> */}
                    <UploadImg
                      setSelectImgHandler={setSelectImgHandler}
                      setProdImgs={setProdImgs}
                      prodImgs={state.prodImgs}
                    />
                    {uploadImageDataError ? (
                      <span className="error">
                        Please upload atleast one image
                      </span>
                    ) : null}
                  </div>
                </div>
                {/* <div className="form-block-wrap">
                                        <label className="enter-mobile-label">Product Specification</label>
                                        <div className="input-wrap">
                                            <input
                                                className={"input-url"}
                                                type="text"
                                                name="product_tag"
                                                placeholder="e.g. 25 %"
                                                onChange={(e) => gradeHandle(e)}
                                                maxLength={25}
                                            />
                                        </div>
                                    </div> */}

                <div className="product-spec-wrapper">
                  <label className="enter-mobile-label">
                    Product Specification (%){" "}
                    <span className="highlighted">*</span>
                  </label>
                  <div className="metal-table-structure">
                    <div className="metal-table-products">
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Si (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => siOneHandle(e)}
                              value={siOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => siTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={siTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={siTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => siTwoHandle(e)} value={siTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">P (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => pOneHandle(e)}
                              value={pOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => pTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={pTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={pTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => pTwoHandle(e)} value={pTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Mo (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => moOneHandle(e)}
                              value={moOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => moTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={moTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={moTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => moTwoHandle(e)} value={moTwo} /> */}
                          </div>
                        </div>
                      </div>

                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Fe (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => feOneHandle(e)}
                              value={feOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => feTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={feTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={feTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mgTwoHandle(e)} value={mgTwo} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="metal-table-products">
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Mn (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => mnOneHandle(e)}
                              value={mnOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => mnTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={mnTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={mnTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mnTwoHandle(e)} value={mnTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">S (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => sOneHandle(e)}
                              value={sOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => sTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={sTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={sTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => sTwoHandle(e)} value={sTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">AI (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => aiOneHandle(e)}
                              value={aiOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => aiTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={aiTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={aiTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => aiTwoHandle(e)} value={aiTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Ca (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => caOneHandle(e)}
                              value={caOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => caTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={caTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={caTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mgTwoHandle(e)} value={mgTwo} /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="metal-table-products">
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">C (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => cOneHandle(e)}
                              value={cOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => cTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={cTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={cTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => cTwoHandle(e)} value={cTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Cr (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => crOneHandle(e)}
                              value={crOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => crTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={crTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={crTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => crTwoHandle(e)} value={crTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product">Mg (%)</div>
                          <div className="metal-table-inputs">
                            <input
                              className="metal-input-table"
                              placeholder="0"
                              type="text"
                              onChange={(e) => mgOneHandle(e)}
                              value={mgOne}
                            />
                            <select
                              className="metal-input-table select-m"
                              onChange={(e) => mgTwoHandle(e)}
                            >
                              <option disabled={true} selected={true}>
                                Select
                              </option>
                              <option value="N/A">N/A </option>
                              <option
                                selected={mgTwo == "max" ? true : false}
                                value="max"
                              >
                                Max
                              </option>
                              <option
                                selected={mgTwo == "min" ? true : false}
                                value="min"
                              >
                                Min
                              </option>
                            </select>
                            {/* <input className='metal-input-table' placeholder='0' type="text" onChange={(e) => mgTwoHandle(e)} value={mgTwo} /> */}
                          </div>
                        </div>
                      </div>
                      <div className="metal-table-panel">
                        <div className="metal-table-line">
                          <div className="metal-heading-product"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {productSpecificationError ? (
                    <span className="error">
                      Please enter atleast one specification
                    </span>
                  ) : null}
                </div>

                {/* <div className="form-block-wrap">
                  <label className="enter-mobile-label">
                    Specification Tolerance (±){" "}
                    <span className="highlighted">*</span>
                  </label>
                  <div className="input-wrap">
                    <input
                      className={"input-url"}
                      type="text"
                      name="product_tag"
                      placeholder="e.g. 0.5 %"
                      onChange={(e) => specTolHandle(e)}
                      maxLength={25}
                      id="specTol"
                      value={specificationTol}
                    />
                  </div>
                  {specificationTolError ? (
                    <span className="error">
                      Please enter specification tolerance
                    </span>
                  ) : null}
                </div> */}

                <div className="delivery-wrapper add-product-delivery">
                  <div className="enter-mobile-label">
                    Delivery<span className="highlighted">*</span>
                  </div>
                  <div className="slct-optns select-wrapper delivery-wrapper-form">
                    <div className="form-block-wrap">
                      <div className="select-main-wrapper">
                        <select
                          onChange={(e) => deliveryFirstHandler(e)}
                          id="deliveryType"
                        >
                          <option value={""}>Select</option>
                          {deliveryFirst.map((item) => (
                            <option
                              value={item._id}
                              selected={
                                deliveryFirstValue == item.name ? true : false
                              }
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <i className="icon-arrow-down"></i>
                      </div>
                      {deliveryFirstValueError ? (
                        <span className="error">
                          Please select delivery type
                        </span>
                      ) : null}
                    </div>
                    {/* <div className='form-block-wrap'>
                                                <div className='select-main-wrapper'>
                                                    <select onChange={(e) => deliverySecondHandler(e)} id="state">
                                                        <option value={""}>Select City</option>
                                                        {
                                                            stateDataArr && stateDataArr.stateData && stateDataArr.stateData.map(item => <option value={item.name} selected={state == item._id ? true : false}>{item.name}</option>)
                                                        }
                                                    </select>
                                                    <i className='icon-arrow-down'></i>
                                                </div>
                                                {deliverySecondValueError ? <span className='error'>Please select state</span> : null}
                                            </div> */}
                    <div className="input-form-wrap select-affilation-profile form-block-wrap">
                      <div className="form-intput select-affilation-wrap complete-profile-select">
                        <div className="select-main-wrapper">
                          <Multiselect
                            id="state"
                            options={optionsSelect} // Options to display in the dropdown
                            selectedValues={selectedValue} // Preselected value to persist in dropdown
                            onSelect={(e) => onSelect(e)} // Function will trigger on select event
                            onRemove={(e) => onRemove(e)} // Function will trigger on remove event
                            displayValue="name" // Property name to display in the dropdown options
                          />
                          <i className="icon-arrow-down"></i>
                        </div>
                        {deliverySecondValueError ? (
                          <span className="error">Please select Region</span>
                        ) : null}
                      </div>
                    </div>
                    <div className="input-form-wrap select-affilation-profile form-block-wrap">
                      <div className="form-intput select-affilation-wrap complete-profile-select">
                        <div className="select-main-wrapper">
                          <div className="select-main-wrapper">
                            <select
                              onChange={(e) => onSelectRegion(e)}
                              id="setCity"
                            >
                              <option value={""}>Select</option>
                              {optionsSelectRegionRegion.map((item) => (
                                <option
                                  value={item.id}
                                  selected={
                                    selectedValueRegion[0] &&
                                    selectedValueRegion[0].id == item.id
                                      ? true
                                      : false
                                  }
                                >
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            <i className="icon-arrow-down"></i>
                          </div>
                        </div>
                        {cityValueError ? (
                          <span className="error">Please select city</span>
                        ) : null}
                      </div>
                    </div>
                    {/* <div className='form-block-wrap input-form-block'>
                                                <div className='select-main-wrapper'>
                                                    <div className="input-wrap">
                                                        <input type="text" className='date-picker-input' placeholder='Enter City' onChange={(e) => deliveryWinThirdHandle(e)} value={city} />
                                                    </div>
                                                </div>
                                            </div> */}
                  </div>
                </div>

                <div className="select-pckage-wrap">
                  <div className="form-block-wrap">
                    <div className="enter-mobile-label">
                      Payment Type<span className="highlighted">*</span>
                    </div>
                    <div className="select-main-wrapper">
                      <select
                        onChange={(e) => paymentTypeHandler(e)}
                        id="paymentType"
                      >
                        <option value={""}>Select</option>
                        {paymentTypeList.map((item) => (
                          <option
                            value={item._id}
                            selected={paymentType == item.name ? true : false}
                          >
                            {item.name}
                          </option>
                        ))}
                      </select>
                      <i className="icon-arrow-down"></i>
                    </div>
                    {paymentTypeError ? (
                      <span className="error">Please select payment type</span>
                    ) : null}

                    {paymentType == "Other" ? (
                      <div className="form-block-wrap payment-other-type">
                        <div className="input-wrap">
                          <input
                            type="text"
                            placeholder="Enter payment type"
                            onChange={(e) => otherPayTypeHandle(e)}
                            id="otherPayment"
                            value={otherPayType}
                          />
                        </div>
                        {otherPayTypeError ? (
                          <span className="error">
                            Please enter payment type
                          </span>
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="delivery-window">
                  <div className="enter-mobile-label">
                    Bundle Weight
                    {/* <span className="highlighted">*</span> */}
                  </div>
                  <div className="date-picker-wrapper">
                    <div className="form-block-wrap">
                      <div className="input-wrap">
                        <input
                          className={"input-url"}
                          type="number"
                          name="coilsCount"
                          placeholder="Number of coils"
                          onChange={(e) => setCoilCount(e.target.value)}
                          id="coilsCount"
                          value={coilsCount}
                        />
                      </div>
                    </div>

                    <div className="form-block-wrap">
                      <div className="input-wrap">
                        <input
                          className={"input-url"}
                          type="text"
                          name="coilWeight"
                          placeholder="Weight (KGs)"
                          onChange={(e) => setCoilWeight(e.target.value)}
                          id="coilWeight"
                          value={coilWeight}
                        />
                      </div>
                      {insuranceChargesError ? (
                        <span className="error">
                          Please enter insurance charges
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="slct-optns select-wrapper">
                  <div className="form-block-wrap">
                    <label className="enter-mobile-label">
                      Loading Charges (INR/mt) (Included in price)
                      {/* <span className="highlighted">*</span> */}
                    </label>
                    <div className="input-wrap">
                      <input
                        className={"input-url"}
                        type="text"
                        name="product_tag"
                        placeholder="e.g. INR 20"
                        onChange={(e) => loadingChangesHandle(e)}
                        maxLength={25}
                        id="loadingCharges"
                        value={loadingChanges}
                      />
                    </div>
                    {loadingChangesError ? (
                      <span className="error">
                        Please enter loading Charges
                      </span>
                    ) : null}
                  </div>

                  <div className="form-block-wrap">
                    <label className="enter-mobile-label">
                      Insurance Charges (INR/mt) (Included in price)
                      {/* <span className="highlighted">*</span> */}
                    </label>
                    <div className="input-wrap">
                      <input
                        className={"input-url"}
                        type="text"
                        name="product_tag"
                        placeholder="e.g. INR 20"
                        onChange={(e) => insuranceChargesHandle(e)}
                        maxLength={25}
                        id="insuranceCharges"
                        value={insuranceCharges}
                      />
                    </div>
                    {insuranceChargesError ? (
                      <span className="error">
                        Please enter insurance charges
                      </span>
                    ) : null}
                  </div>
                </div>

                <div className="form-block-wrap">
                  <label className="enter-mobile-label">
                    Remarks
                    {/* <span className="highlighted">*</span> */}
                  </label>
                  <div className="input-wrap">
                    <input
                      className={"input-url"}
                      type="text"
                      name="remarks"
                      onChange={(e) => setRemarks(e.target.value)}
                      maxLength={300}
                      id="insuranceCharges"
                      value={remarks}
                    />
                  </div>
                  {insuranceChargesError ? (
                    <span className="error">
                      Please enter insurance charges
                    </span>
                  ) : null}
                </div>

                <div className="attr">
                  <div className="navigation-container both-btn">
                    {!params.id ? (
                      <div className="navigation-back">
                        <button className="next-btn">Upload Product</button>
                      </div>
                    ) : (
                      <div>
                        <button
                          // onClick={props.submitFun}
                          className="next-btn"
                        >
                          Update Product
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          !isEmpty(userProfile) && (
            <div>
              <AccVerifiedPopup
                verification={
                  userProfile.userdata && userProfile.userdata.verified
                }
              />
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default AddWireProduct;
