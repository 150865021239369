import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { profileRoutes } from "./profileRoutes";
import { useLocation, Link } from "react-router-dom";
import { apiUrl } from "../../../utils/urlEndpoints";
import { axiosInstance } from "../../../utils/Service";
import { getProfileBannerInfo } from "../../../store/actions/profile";
import { RatingStar } from "../../ProductDetail/productReview";

const LeftPanelProfile = (props) => {
  const [profileDate, setProfileData] = React.useState({});
  const [profileDataFull, setProfileDataFull] = React.useState({});
  const location = useLocation();
  const [userId] = React.useState(localStorage.getItem("userId"));
  const [status, setStatus] = React.useState(true);
  const [type, settype] = React.useState(true);

  useEffect(() => {
    const token = localStorage.getItem("token");
    axiosInstance
      .post(apiUrl.profile.profileBannerInfo, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setStatus(res.data.userdata.isSeller);
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(props.profileInfo)) {
      !isEmpty(props.profileInfo.userdata)
        ? (setProfileData(props.profileInfo.userdata))
        : setProfileData({});

      !isEmpty(props.profileInfo.userdata)
        ? (setProfileDataFull(props.profileInfo))
        : setProfileDataFull({});
    }
  }, [props.profileInfo]);

  return (
    <div className="profile-left-panel left-profile-wrapper">
      <div className="profile-div">
      
        <figure className="profile-img">
          {console.log("profileDate.user_image",profileDate.user_image)}
          <img
            src={
              profileDate.user_image
                ? profileDate.user_image
                : "/assets/images/avtar.png"
            }
            alt="user"
            style={{objectFit: "contain", objectPosition: "initial"}}
          />
          <i
            onClick={(e) => {
              const upload = document.querySelector("#uploadProfile");
              upload.click();
            }}
            className="icon-edit-img"
          />
        </figure>
        <div style={{ display: "none" }}>
          <input
            type="file"
            id="uploadProfile"
            accept=".jpg,.png,.jpeg"
            onChange={(e) => props.uploadImage(e)}
          />
        </div>

        {profileDataFull.average_rating
          ? <RatingStar
            value={profileDataFull.average_rating}
          />:null}

          
        {profileDate.full_name ? (
          <Link
            to={{
              pathname: "/profile",
            }}
            onClick={props.setValue}
            className="edit-profile-link"
          >
            <span className="profile-name profile-name-main">
              {profileDate.full_name}
            </span>
            <div className="edit-wrap-profile">
              <Link
                to={{ pathname: "/profile/complete_profile", query: "edit" }}
              >
                <i class="fas fa-user-edit"></i>
              </Link>
            </div>
          </Link>
        ) : null}



        {/* <button className="header-sell" onClick={props.logout}>
          <i className="fas fa-power-off"></i>Log Out
        </button> */}
      </div>
      {profileDate.userType == "Seller" ? (
        <div className="left-item-list">
          <>
            <h4 className="seller-head">Seller</h4>
            {profileRoutes.map((data) => {
              return (
                <>
                  {data.for === "seller" && !(data.name==="Role and Permission" && localStorage.getItem("childType")==="all") ? (
                    <>
                    {(data.name=="Create Order")?null:
                      <div
                        className={`left-menu-block ${location.pathname
                            .replace("/profile/", "/")
                            .split("/")[1] !== "myprofile"
                            ? data.pathname.replace("/", "") ===
                              location.pathname
                                .replace("/profile/", "/")
                                .split("/")[1]
                              ? "active-tab"
                              : ""
                            : data.pathname.split("/")[1] ===
                              location.pathname
                                .replace("/myprofile/", "/")
                                .split("/")[1]
                              ? "active-tab"
                              : ""
                          }`}
                      >
                        <Link
                          to={{ pathname: data.prefix + data.pathname }}
                          className={
                            location.pathname
                              .replace("/profile/", "/")
                              .split("/")[1] !== "myprofile"
                              ? data.pathname.replace("/", "") ===
                                location.pathname
                                  .replace("/profile/", "/")
                                  .split("/")[1]
                                ? "active-tab"
                                : ""
                              : data.pathname.split("/")[1] ===
                                location.pathname
                                  .replace("/myprofile/", "/")
                                  .split("/")[1]
                                ? "active-tab"
                                : ""
                          }
                        >
                          <i className={data.icon} />
                          <span className="left-menu-data-name">
                            {data.name}
                          
                          </span>
                        </Link>
                      </div>
                         }
                    </>
                  ) : null}
                </>
              );
            })}
            <Link onClick={props.logout}>
              <i className="fas fa-power-off"></i>
              <span>Log Out</span>
            </Link>

            {localStorage.getItem("incomplete") == "true" ? <></> : null}
          </>
          {/* <h4 className="buyer-head">Buyer</h4>
          <>
            {profileRoutes.map((data) => {
              if (data.for === "user") {
                  return (
                    <>
                      <Link
                        to={{ pathname: data.prefix + data.pathname, }}

                        className={location.pathname.replace("/profile/", "/").includes(data.pathname) ? "active-tab" : ""}
                      >
                        <i className={data.icon} />
                        <span>{data.name}</span>
                      </Link>
                    </>
                  )
              }
            })}
          </> */}
        </div>
      ) : (
        <div className="left-item-list left-list-wrap css-hack">
          <h4 className="buyer-head">Buyer</h4>
          {profileRoutes.map((data) => {
            if (data.for === "user") {
               // Check if the route is "Request for quotes" and the user has access
              if (data.name === "Request For Quote (RFQ)" && profileDataFull && profileDataFull.userdata && !profileDataFull.userdata.rfq_access) {
                return null; // Don't render this route if rfq_access is false
              }
              return (
                !(data.name==="Role and Permission" && localStorage.getItem("childType")==="all") ? <div className="left-menu-block buyer-det">
                  <Link
                    to={{ pathname: data.prefix + data.pathname }}
                    className={
                      location.pathname
                        .replace("/profile/", "/")
                        .split("/")[1] !== "myprofile"
                        ? data.pathname.replace("/", "") ===
                          location.pathname
                            .replace("/profile/", "/")
                            .split("/")[1]
                          ? "active-tab"
                          : ""
                        : data.pathname.split("/")[1] ===
                          location.pathname
                            .replace("/myprofile/", "/")
                            .split("/")[1]
                          ? "active-tab"
                          : ""
                    }
                  >
                    <i className={data.icon} />
                    <span className="left-menu-data-name">{data.name}</span>
                  </Link>
                </div>:null
              );
            }
          })}
          <Link onClick={props.logout}>
            <i className="fas fa-power-off"></i>
            <span>Log Out</span>
          </Link>
        </div>
      )}
    </div>
  );
};
export default LeftPanelProfile;
