import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { apiUrl } from "../../../utils/urlEndpoints";
import { axiosInstance } from "../../../utils/Service";
import { getProfileBannerInfo } from "../../../store/actions/profile";
import { getActiveStatus } from "../../../store/actions/common";
import { useSelector } from "react-redux";

const ProfileDropDown = (props) => {
  const [status, setStatus] = React.useState("");
  const [image, setImage] = React.useState("");
  const [name, setname] = React.useState("");
  const [phone, setphone] = React.useState("");
  const [show, setshow] = React.useState(false);
  const [hover, sethover] = React.useState(true);
  const [addClass, setaddClass] = React.useState("");
  // const [matches, setMatches] = React.useState(
  //   // window.matchMedia("(min-width:900px)").matches
  //   true
  // );
  const [apStatus, setApStatus] = React.useState("");
  const dispatch = useDispatch();

  React.useEffect(() => {
    props.getProfileBannerInfo();

    const token = localStorage.getItem("token");
    const approveStatus = localStorage.getItem("approved");
    setApStatus(approveStatus);
    axiosInstance
      .post(apiUrl.profile.profileBannerInfo, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        setStatus(res.data.userdata && res.data.userdata.verified);

        setImage(res.data.userdata && res.data.userdata.user_image);
        setname(res.data.userdata && res.data.userdata.first_name);
        setphone(res.data.userdata && res.data.userdata.mobile);
      });
  }, []);

  function managePopup() {
    setshow(!show);
  }

  // const handleProfile = () => {
  //   props.setValue();
  //   setshow(false);
  // };

  // const handleChangePassword = () => {
  //   props.setValue();
  //   setshow(false);
  // };

  const handlelogout = () => {
    setshow(false);
    dispatch(getActiveStatus({}, (status) => { }));
    // setTimeout(() => {
    // }, 1000);

    props.logout();
  };

  return (
    <>
      <div className={"userName-container profile-user-name" + addClass}>
        <div className="signin-register" onClick={managePopup}>
          <span className="profile-name">
            {Object.keys(props.userProfileBanner).length == 0
              ? ""
              : props.userProfileBanner.userdata &&
              props.userProfileBanner.userdata.full_name}
          </span>
        </div>

        {/* {apStatus == "pending" ? 
      <>
     
      </>   : null
      } */}

        <div className="logout-container">
          {apStatus == "pending" ? null : (
            <>
              <div className="logout-profile-wrap">
                <div className="profile-img">
                  <Link
                    className="user-img"
                    to={{
                      pathname: "/profile",
                    }}
                    onClick={props.setValue}
                  >
                    <img
                      src={
                        props.userData &&
                          props.userData.userdata &&
                          props.userData.userdata.user_image
                          ? props.userData.userdata.user_image
                          : "/assets/images/avtar.png"
                      }
                    />
                  </Link>
                </div>

                <div className="profile-description">
                  <Link
                    to={{
                      pathname: "/profile",
                    }}
                    onClick={props.setValue}
                  >
                    <h4>{name}</h4>

                    <a>{phone}</a>
                  </Link>
                </div>
              </div>

              {props.userProfileBanner &&
                props.userProfileBanner.userdata &&
                props.userProfileBanner.userdata.userType == "Seller" &&
                apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/profile/seller/dashboard",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> Seller
                    Dashboard
                  </Link>
                </div>
              ) : null}

              <div>
                <Link
                  to={{
                    pathname: "/profile",
                  }}
                  onClick={props.setValue}
                >
                  <i className="fa fa-user" aria-hidden="true"></i> My
                  Profile
                </Link>
              </div>

              {props.userProfileBanner &&
                props.userProfileBanner.userdata &&
                props.userProfileBanner.userdata.userType == "Seller" && 
                props.userProfileBanner.userdata.create_listing_access  &&
                apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/sell-product",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> Create
                    Listing
                  </Link>
                </div>
              ) : null}

             {props.userProfileBanner &&
                props.userProfileBanner.userdata &&
                props.userProfileBanner.userdata.userType == "Seller" &&
                props.userProfileBanner.userdata.create_wire_listing_access &&
                apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/wire/sell-product",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> Create
                    Wire Listing
                  </Link>
                </div>
              ) : null}

            {props.userProfileBanner &&
              props.userProfileBanner.userdata &&
              props.userProfileBanner.userdata.userType == "Seller" &&
              props.userProfileBanner.userdata.isSuperSeller &&
              apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/add-order",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> 
                    Create Order
                  </Link>
                </div>
              ) : null}

              {props.userProfileBanner &&
                props.userProfileBanner.userdata &&
                props.userProfileBanner.userdata.userType == "Seller" &&
                apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/profile/my-listing/active",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> My
                    Listings
                  </Link>
                </div>
              ) : null}

              {props.userProfileBanner.userdata && props.userProfileBanner.userdata.rfq_access && (props.userProfileBanner.userdata.userType == "Buyer" || props.userProfileBanner.userdata.isSuperSeller || props.userProfileBanner.userdata.userType == "Seller") && <div>
                <Link
                  to={{
                    pathname: "/profile/rfq-offers/active",
                  }}
                  onClick={props.setValue}
                >
                  <i className="fa fa-user" aria-hidden="true"></i> Request For Quote (RFQ)
                </Link>
              </div>}

              {props.userProfileBanner &&
                props.userProfileBanner.userdata &&
                props.userProfileBanner.userdata.userType == "Seller" &&
                apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/profile/my-offer/pending",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> My
                    Offers
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to={{
                      pathname: "/profile/my-offer/pending",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> My
                    Offers
                  </Link>
                </div>
              )}

              {props.userProfileBanner &&
                props.userProfileBanner.userdata &&
                props.userProfileBanner.userdata.userType == "Seller" &&
                apStatus == "accept" ? (
                <div>
                  <Link
                    to={{
                      pathname: "/profile/seller-order/pending",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> My
                    Orders
                  </Link>
                </div>
              ) : (
                <div>
                  <Link
                    to={{
                      pathname: "/profile/myorder/pending",
                    }}
                    onClick={props.setValue}
                  >
                    <i className="fa fa-user" aria-hidden="true"></i> My
                    Orders
                  </Link>
                </div>
              )}

              <div>
                <Link
                  to={{
                    pathname: "/profile/change-password",
                  }}
                  onClick={props.setValue}
                >
                  <i className="fa fa-key" aria-hidden="true"></i> Change
                  Password
                </Link>
              </div>
            </>
          )}

          {localStorage.getItem("childType")!=="all" &&
                apStatus == "accept" && <div>
            <Link
              to={{
                pathname: "/profile/role-permission",
              }}
            >
              <i className="fa fa-user" aria-hidden="true"></i> Role and Permission
            </Link>
          </div>}

          <div onClick={() => handlelogout()}>
            <span className="logout-link">
              <i className="fa fa-sign-out" aria-hidden="true"></i> Log Out
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

//export default ProfileDropDown;

const mapDispatchToProps = (dispatch) => {
  return {
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  userData: state.userProfile.userProfileBanner,
});
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileDropDown)
);
