import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../utils/Service";

const RfqCounterOffersDetail = (props) => {
  const [counterOffers, setCounterOffers] = useState([]);
  const [sellerData, setSellerData] = useState([]);

  const params = useParams();
  const prodId = props.prodId || params.prodId;
  const sellerId = props.sellerId || params.sellerId;
  const userData = props.userData || {userType: 'Buyer'};

  useEffect(() => {
    let token = localStorage.getItem("token");
    axiosInstance
      .post(
        `/requestForQuote/request_details`,
        {
          userType: userData.userType,
          requestId: prodId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          }
        }
      )
      .then((res) => {
        const matchingSellerData = res.data.data.sellerData
          ? res.data.data.sellerData.find((seller) => seller.sellerId === sellerId)
          : null;

        setSellerData(matchingSellerData)

        const counterOffersData = matchingSellerData ? matchingSellerData.counterOffer : [];

        setCounterOffers(counterOffersData);
      });
  }, []);

  return (
    <div className="ordered-products order-product-wrp">
      <div className="order-left order-delivery-location">
        <div className="order-left-top">
          <span className="order-title">Offer Information</span>
        </div>
      </div>

      {counterOffers.length > 0 ? (
        counterOffers
          .slice() // Create a copy of the array to avoid mutating the original
          .reverse() // Reverse the order of the array
          .map((offer, index) => (
            <div className="offer-column" key={index}>
              <h5>
                {offer.offerFrom === "Buyer"
                  ? userData.userType === "Buyer"
                    ? "Your offer"
                    : "Buyer counter offer"
                  : userData.userType === "Seller"
                  ? "Your offer"
                  : "Seller counter offer"}
              </h5>

              <div className="multiple-items products-images products-images-wrapper pending-order">
                <div className="cart-item-detail">
                  <div className="value">
                    <div className="current-value">Price:</div>
                    <div className="new-value">₹{offer.price}/mt</div>
                  </div>
                  <div className="value">
                    <div className="current-value">Quantity:</div>
                    <div className="new-value">{offer.quantity}</div>
                  </div>
                  <div className="value">
                    <div className="current-value">Payment Terms:</div>
                    <div className="new-value">{offer.paymentTerms}</div>
                  </div>
                  <div className="value">
                    <div className="current-value">Delivery:</div>
                    <div className="new-value">{offer.delivery}</div>
                  </div>
                  <div className="value">
                    <div className="current-value">Comments:</div>
                    <div className="new-value">{offer.comments}</div>
                  </div>
                </div>
                {index === 0 && userData.userType === "Seller" ? (
                  <div className="rfq-detail" style={{ alignSelf: "start" }}>
                    {sellerData.isSellerAccepted ? (
                      <span style={{color: "green"}}>You have accepted this offer!</span>
                    ) : (
                      <button
                        onClick={props.handleSellerAccept}
                        className="date-wrp make-new-payment-btn pay-btn"
                      >
                        Accept
                      </button>
                    )}
                    <button
                      onClick={props.handleSellerCounter}
                      className="date-wrp make-new-payment-btn pay-btn"
                    >
                      Counter
                    </button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          ))
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <div>No counter offers available.</div>
          <div className="rfq-detail" style={{ alignSelf: "start" }}>
            {/* <button
              onClick={props.handleSellerAccept}
              className="date-wrp make-new-payment-btn pay-btn"
            >
              Accept
            </button> */}
            <button
              onClick={props.handleSellerCounter}
              className="date-wrp make-new-payment-btn pay-btn"
            >
              Respond
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RfqCounterOffersDetail;
