import React from "react";
import { connect } from "react-redux";
import Toast from "light-toast";
import {
  loginRequest,
  socialLogin,
  forgotPassword,
  OTPVerify,
  OTPLoginVerify,
  resendMobileOTP,
  resendEmailLink,
  getToken,
  forgotPasswordOtp,
  setPassword,
  resendOTP,
  signupByMobile,
} from "../../store/actions/common";
import { getProfileBannerInfo } from "../../store/actions/profile";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import { Helmet } from "react-helmet";
import ForgotPass from "./ForgotPass";
import { useToasts } from "react-toast-notifications";
import TextField from "@material-ui/core/TextField";
import OTPForm from "./OTPform";
import { Link } from "react-router-dom";
import LoginWithOtp from "./loginWithOtp";
import Loader from "../Loader";
import { withRouter } from "react-router-dom";
import Signup from "./Signup";
import SellerContact from "./SellerContact";
import { onUserLogin } from "../../utils/Analytics";
import { setLoggedUserInfo } from "../../store/actions/common";
import LoginUsingOtp from "./LoginUsingOtp";
import { axiosInstance } from "../../utils/Service";
// import { nonWhiteSpace } from "html2canvas/dist/types/css/syntax/parser";
import Select from 'react-select';

class Login extends React.Component {
  constructor(props) {
    super(props);
    const queryString = window.location.search;
    const queryParams = new URLSearchParams(queryString);
    console.log("queryString", queryParams);
    let isSuperAdminMode = false;
    if (
      queryParams.has("superadmin") &&
      queryParams.get("superadmin") === "true"
    ) {
      isSuperAdminMode = true;
    }
    this.state = {
      email: "",
      superAdminEmail: "admin@metalsbuy.com",
      isSuperAdminMode,
      password: "",
      showLoginPopup: this.props.showLoginPopup,
      loginErr: false,
      showInputForm: true,
      showForgotPassword: false,
      rememberMe: false,
      errorMsg: "",
      status: false,
      showPass: false,
      otpForm: false,
      showOTPform: false,
      OTP: "",
      token: "",
      time: 50,
      isEmail: true,
      number: null,
      numberError: false,
      emailError: false,
      isPhoneNumber: false,
      userId: "",
      registered: null,
      showRegisterForm: false,
      showLoader: false,
      showMessage: "",
      showSignUpForm: false,
      showSellerContactForm: false,
      showEmailPasswordSignIn: isSuperAdminMode,
      mobileNumberForOtpLogin: '',
      userList: []
    };
  }

  changeInput = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      if (e.target.value.length <= 10) {
        this.setState({
          numberError: false,
          emailError: false,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  changeInputEmail = (e) => {
    let email_phone = e.target.value;
    let isValidMobile = isNaN(email_phone);
    var filter =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    let isValidMail = filter.test(email_phone);
    if ((!isValidMobile && email_phone.length === 10) || isValidMail) {
      this.setState({
        numberError: false,
        [e.target.name == "email"
          ? "emailError"
          : "superAdminEmailError"]: false,
        [e.target.name]: e.target.value,
      });
    } else {
      this.setState({
        [e.target.name == "email"
          ? "emailError"
          : "superAdminEmailError"]: true,
        [e.target.name]: e.target.value,
      });
    }
  };

  changeInputPass = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  rememberMe = () => {
    this.setState({ rememberMe: !this.state.rememberMe });
  };

  formSubmit = (e, userType) => {
    e.preventDefault();
    if (this.state.emailError) {
      return true;
    }

    // this.setState({ OTP: "" });
    if (this.state.isPhoneNumber) {
      ///////////////  Checking User /////////////
      e.preventDefault();
      if (this.state.email.length < 10) {
        return this.props.addToast("Please enter 10 digit Phone Number", {
          appearance: "error",
        });
      }

      this.setState({ showLoader: true, showMessage: "Please wait..." });
      let payload = {
        mobile: this.state.email,
      };

      this.props.loginRequest(payload, (status) => {
        if (status.data.status == "success") {
          this.setState({ showLoader: false });
          // this.setState({ showOTPform: true, userId: status.data.userId, registered: status.data.register });
          this.setState({
            otpForm: true,
            showInputForm: false,
            userId: status.data.userId,
            registered: status.data.register,
          });
        } else {
          this.setState({ showLoader: false });
          this.props.addToast(status.data.errData, { appearance: "error" });
        }
      });
    } else {
      var checkValue = isNaN(this.state.email);
      this.setState({ showLoader: true, showMessage: "Please wait..." });
      var email = this.state.email;
      var payload = {
        loginKey: email,
        password: this.state.password,
      };

      let superAdminEmail = this.state.superAdminEmail;
      if (superAdminEmail) {
        payload = {
          ...payload,
          superAdminEmail,
          password: "",
          superAdminPassword: this.state.password,
        };
      }

      localStorage.setItem("rememberMe", this.state.rememberMe);
      localStorage.setItem("userEmail", email);
      localStorage.setItem(
        "bazaarEmail",
        this.state.rememberMe ? payload.email : ""
      );
      localStorage.setItem(
        "bazaarPass",
        this.state.rememberMe ? payload.password : ""
      );

      this.props.loginRequest(payload, (status) => {
        if (status.data.status == "success") {
          if (!superAdminEmail) {
            onUserLogin(email || payload.loginKey);
          } else {
            localStorage.setItem(
              "isSuperAdmin",
              superAdminEmail ? true : false
            );
          }
          this.props.getProfileBannerInfo();
          this.setState({ showLoader: false });
          this.props.addToast("Login Successfully", { appearance: "success" });
          // localStorage.setItem("isProfileCompleted", status.data.isProfileCompleted);
          localStorage.setItem("approved", status.data.userData.Approval);
          localStorage.setItem("userType", status.data.userData.userType);
          // localStorage.setItem("reason", status.data.userData.rejectReason);

          // For refresh
          window.location.reload();
        } else {
          this.setState({ showLoader: false });
          if (Object.keys(status).includes("mobileVerified")) {
            //if response contain {mobileVerified:false} ex mobile otp related

            this.setState({
              otpForm: true,
              showInputForm: false,
              token: status.token,
            });
            this.props.addToast(status.message, { appearance: "success" });
          } else if (Object.keys(status).includes("emailVerified")) {
            //if response contain {emailVerified:false} ex email verify link related

            this.props.addToast(status.message, { appearance: "success" });
          } else {
            this.setState({
              errorMsg: status.data.errData,
              status: status.data.status,
            });
            this.props.addToast(status.data.message, { appearance: "error" });
            //window.location.reload();
          }
        }
      });

      const shippingPayload = {
        password: "123456",
        rememberMe: true,
        username: "vamshi1012@mailinator.com",
      };
      this.props.getToken(shippingPayload, (status) => {
        const tokenDetail = {
          token: status.res.data.idToken,
          accountOwnerId: status.res.data.accountOwnerId,
          organizationId: status.res.data.organizationId,
          userId: status.res.data.userId,
        };
        localStorage.setItem(
          "ShippingTokenDetail",
          JSON.stringify(tokenDetail)
        );
      });

      //var email = this.state.email;

      ////// Api /////////////
    }
  };

  mobileLogin = (payload) => {
    this.setState({ showLoader: true, showMessage: "Please wait..." });
    this.props.signupByMobile(payload, (status) => {
      if (status.status) {
        if (status.data.isSellerRegistration) {
          this.props.closePopup(true);
          this.props.history.push("/sellerRegistration/" + status.data.userId);
        } else {
          this.setState({ showLoader: false });
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      } else {
        this.setState({ showLoader: false });
        this.props.addToast(status.data.message, { appearance: "error" });
      }
    });
  };

  submitOTP = () => {
    if (this.state.isPhoneNumber) {
      this.setState({ showLoader: true, showMessage: "Please wait..." });
      const params = {
        otp: this.state.OTP,
        userId: this.state.userId,
      };

      this.props.OTPLoginVerify(params, (callback) => {
        if (callback.status) {
          if (this.state.registered) {
            this.setState({ showLoader: false });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            this.setState({ showLoader: false });
            this.setState({ showOTPform: false });
            this.setState({ showRegisterForm: true.valueOf, otpForm: false });
          }
        } else {
          this.setState({ showLoader: false });
          this.props.addToast(callback.data.message, { appearance: "error" });
        }
      });
    } else {
      const params = {
        otp: this.state.OTP,
        token: this.state.token,
      };
      this.props.OTPVerify(params, (callback) => {
        if (callback.status) {
          this.setState({ showLoader: false });
          this.props.addToast("OTP verified success", {
            appearance: "success",
          });

          this.props.addToast(
            callback.data.message,
            { appearance: "success" },
            () => {
              this.props.closePopup(true);
            }
          );
        } else {
          this.props.addToast(callback.data.message, { appearance: "error" });
        }
      });
    }
  };

  resendMobOTP = () => {
    this.setState({ showLoader: true, showMessage: "Please wait" });
    if (this.state.isPhoneNumber) {
      if (this.state.userId === "") {
        var payload = {
          mobile: this.state.email,
          type: "login",
        };
      } else {
        var payload = {
          userId: this.state.userId,
          type: "login",
        };
      }
    } else {
      var payload = {
        token: this.state.token,
      };
    }

    this.props.resendMobileOTP(payload, (callback) => {
      this.props.addToast(callback.message, { appearance: "success" });
      this.setState({ time: 50 });
      this.setState({ showLoader: false });
    });
  };

  resendEmail = () => {
    this.props.resendEmailLink({ token: this.state.token }, (callback) => {
      this.props.addToast(callback.message, { appearance: "success" });
      this.setState({ time: 50 });
    });
  };

  responseFacebook = (response) => {
    if (response.status !== "unknown") {
      const name = response.name.split(" ");
      const first_name = name[0];
      const last_name = name[name.length - 1];
      const email = response.email;
      const socialId = response.id;
      const payload = {
        first_name,
        last_name,
        email,
        socialId,
        provider: "facebook",
      };
      this.props.socialLogin(payload, (status) => {
        if (status) {
          this.props.getProfileBannerInfo();
          this.setState({ showLoader: false });
          this.props.addToast("Signed with Facebook", {
            appearance: "success",
          });
          this.props.closePopup(false);
        } else {
          this.setState({ showLoader: false });
          this.props.addToast("Login failed", { appearance: "error" });
        }
      });
    }
  };

  responseGoogle = (response) => {
    this.setState({ showLoader: true, showMessage: "Loading Google Login..." });
    const data = response.profileObj;
    const first_name = data.givenName;
    const last_name = data.familyName;
    const email = data.email;
    const socialId = data.googleId;

    const payload = {
      first_name,
      last_name,
      email,
      socialId,
      provider: "google",
    };
    this.props.socialLogin(payload, (status) => {
      if (status) {
        this.props.getProfileBannerInfo();
        this.setState({ showLoader: false });
        this.props.addToast("Signed with Google", { appearance: "success" });
        this.props.closePopup(false);
      } else {
        this.setState({ showLoader: false });
        this.props.addToast("Login failed", { appearance: "error" });
      }
    });
  };

  // API Call to fetch sellerList and buyerList
  fetchUserLists = async () => {
    try {
      const sellerData = await axiosInstance.get('/requestForQuote/seller_list');

      const buyerData = await axiosInstance.get('/requestForQuote/buyer_list');

      // Filter out sellers and buyers where either companyName or email is missing
      const filteredSellerData = sellerData.data.data.filter((seller) => seller.companyName && seller.email);
      const filteredBuyerData = buyerData.data.data.filter((buyer) => buyer.companyName && buyer.email);


      // Once both API calls have finished, update the state with the results
      const combinedList = [
        ...filteredSellerData.map((seller) => ({
          companyName: seller.companyName,
          email: seller.email,
          userType: 'Seller',
        })),
        ...filteredBuyerData.map((buyer) => ({
          companyName: buyer.companyName,
          email: buyer.email,
          userType: 'Buyer',
        })),
      ];

      // Sort the combined list alphabetically by company name
      combinedList.sort((a, b) =>
        a.companyName.localeCompare(b.companyName)
      );

      this.setState({ userList: combinedList });

    } catch (error) {
      console.error('Error fetching user lists:', error);
    }
  };

  componentDidMount() {
    const rememberMe = localStorage.getItem("rememberMe") === "true";
    const email = rememberMe ? localStorage.getItem("bazaarEmail") : "";
    const password = rememberMe ? localStorage.getItem("bazaarPass") : "";
    // this.setState({ email, password, rememberMe });

    this.fetchUserLists();
  }

  // handleEnter = (event) => {
  //   if (event.keyCode === 13) {
  //     const form = event.target.form;
  //     const index = Array.prototype.indexOf.call(form, event.target);
  //     form.elements[index + 1].focus();
  //     event.preventDefault();
  //   }
  // };

  handleEmailLogin = () => {
    this.setState({ isPhoneNumber: false, email: "" });
  };

  switchLoginScreen = () => {
    this.setState({ showOTPform: false });
    this.props.switchScreen("login");
  };

  handlePhoneLogin = () => {
    this.setState({
      isPhoneNumber: true,
      email: "",
      emailError: false,
      numberError: false,
    });
  };
  backHandler = () => {
    this.setState({ showInputForm: true, otpForm: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleSignUp = () => {
    this.setState({
      showInputForm: false,
      showSignUpForm: true,
      showSellerContactForm: false,
    });
  };

  handleLogin = () => {
    this.setState({
      showInputForm: true,
      showSignUpForm: false,
      showSellerContactForm: false,
    });
  };

  handleSellerContact = () => {
    this.setState({
      showInputForm: false,
      showSignUpForm: false,
      showSellerContactForm: true,
    });
  };

  closePopup = () => {
    this.props.closePopup(true);
  };

  handleShowEmailPasswordSignIn = () => {
    this.setState({
      showEmailPasswordSignIn: !this.state.showEmailPasswordSignIn
    });
  }

  render() {
    const {
      email,
      superAdminEmail,
      isSuperAdminMode,
      password,
      rememberMe,
      showInputForm,
      status,
      errorMsg,
      showPass,
      showOTPform,
      OTP,
      time,
    } = this.state;
    return (
      <>

        
        {this.state.showEmailPasswordSignIn ? (
        <div
          className={
            "signup-form signup-frm-wrp user-login login-page-wrapp " +
            (this.state.showSignUpForm ? "hide-signin" : "") +
            (showOTPform ? "otp-open" : "")
          }
        >
          {this.state.showLoader ? (
            <Loader message={this.state.showMessage} />
          ) : null}
          <Helmet>
            <style>
              {`
           .facebook-button-holder, .google-button-holder {
            position: relative;
            margin-bottom:20px;
           }
           .facebook-button-holder button, .google-button-holder button {
            width:100%;
           }
            .facebook-button-holder span button, .google-button-holder button:first-child {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 15;
              width: 100%;
              height: 100%;
              opacity:0 !important;
              
            }
            `}
            </style>
          </Helmet>
          <button
            className="close-btn"
            onClick={() => this.props.closePopup(true)}
          >
            <i className="fal fa-times-circle"></i>
          </button>

          <div
            className="inner-sign sign-in-without-img"
            style={showOTPform ? { justifyContent: "space-around" } : {}}
          >
            <>
              <div className="sign-form forget-password-signin-form login-page-wrapp">
                {this.state.showForgotPassword && (
                  <ForgotPass
                    forgotPassword={this.props.forgotPassword}
                    forgotpasswordotp={this.props.forgotPasswordOtp}
                    forgotpasswordresend={this.props.resendOTP}
                    setPassword={this.props.setPassword}
                    forgotMsg={this.props.forgotMsg}
                    closePopup={this.props.closePopup}
                    setState={(key, val) => this.setState({ [key]: val })}
                    time={time}
                  />
                )}
                {this.state.otpForm ? (
                  <OTPForm
                    OTP={OTP}
                    setState={(key, val) => this.setState({ [key]: val })}
                    submitOTP={this.submitOTP}
                    time={time}
                    resendMobOTP={this.resendMobOTP}
                    resendEmail={this.resendEmail}
                    mobileNumber={this.state.email}
                    switchScreen={this.switchLoginScreen}
                    backHandler={this.backHandler}
                  />
                ) : null}
                {this.state.showRegisterForm ? (
                  <LoginWithOtp
                    mobile={this.state.email}
                    login={this.mobileLogin}
                    getType={this.state.showLoginPopup}
                  />
                ) : null}
                {showInputForm && (
                  <form onSubmit={(e) => this.formSubmit(e, "user")}>
                    <div className="detail-from">
                      <div className="user-detail-edit user-signin-details">
                        <div className="logo-wrapper">
                          <Link to="/" className="logo-wrap">
                            <img
                              src="/assets/images/metal-buy-logoo.png"
                              alt="logo"
                            />
                          </Link>
                        </div>
                      </div>
                      {this.state.isPhoneNumber ? (
                        <>
                          <div className="enter-mobile-label">
                            Email / Mobile no.
                          </div>
                          <div className="form-input number-code-wrapper sign-in-mobile">
                            <span className="number-code">+91</span>
                            <TextField
                              variant="outlined"
                              required
                              type="text"
                              name="email"
                              // label="Mobile Number"
                              placeholder="Enter email or mobile no."
                              value={email}
                              onChange={(e) => this.changeInput(e)}
                              onKeyDown={this.handleEnter}
                              maxLength="10"
                            />
                            {this.state.emailError ? (
                              <span className="error">
                                Please Enter Valid Email
                              </span>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="enter-mobile-label">
                            Email / Mobile no.
                          </div>
                          <div className="form-input">
                            {/* <TextField
                              variant="outlined"
                              required
                              type="text"
                              name="email"
                              placeholder="Enter email or mobile no."
                              value={email}
                              onChange={(e) => this.changeInputEmail(e)}
                              onKeyDown={this.handleEnter}
                              maxLength="10"
                            /> */}
                            
                              
                            <div className="names-wrapper form-block-wrap">
                              <div className="input-form-wrap select-affilation-profile address-name-wrap">
                                  {/* <label className="enter-mobile-label">
                                      Select payment terms*
                                  </label> */}
                                  <div className="select-main-wrapper" style={{marginTop: "10px"}}>
                                      <Select
                                        options={this.state.userList}
                                        value={this.state.userList.find(item => item.email === this.state.email)} // Pre-select based on email
                                        onChange={(selectedOption) => this.setState({ email: selectedOption.email })} // Set email from selected option
                                        placeholder="Select a company"
                                        getOptionLabel={(e) => `${e.companyName} (${e.userType})`}
                                        getOptionValue={(option) => option.email} // changes here!!!
                                        isSearchable
                                        noOptionsMessage={() => 'No matches found'}
                                        styles={{
                                          control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            paddingLeft: '15px',
                                          }),
                                          dropdownIndicator: (base) => ({
                                            ...base,
                                            padding: 0,            // Remove padding around the arrow
                                            margin: 0,             // Remove margin for even spacing
                                            cursor: 'pointer',
                                          }),
                                          indicatorSeparator: () => ({
                                            display: 'none',        // Hide the separator line
                                          }),
                                        }}
                                      />
                                      {/* <i className="icon-arrow-down"></i> */}
                                  </div>
                              </div>                                                                
                            </div>

                            {this.state.emailError ? (
                              <span className="error">
                                Please enter valid Email / Mobile no.
                              </span>
                            ) : null}
                          </div>
                          {isSuperAdminMode && (
                            <div className="form-input">
                              <TextField
                                variant="outlined"
                                required
                                type="text"
                                name="superAdminEmail"
                                placeholder="Enter superadmin email."
                                value={superAdminEmail}
                                onChange={(e) => this.changeInputEmail(e)}
                                // onKeyDown={this.handleEnter}
                                maxLength="10"
                              />
                              {this.state.superAdminEmailError ? (
                                <span className="error">
                                  Please enter valid Email / Mobile no.
                                </span>
                              ) : null}
                            </div>
                          )}
                        </>
                      )}

                      {this.state.isPhoneNumber ? null : (
                        <div className="paswrd-wrp">
                          <div className="enter-mobile-label">
                            Enter Password
                          </div>
                          <div className="form-input">
                            <TextField
                              variant="outlined"
                              InputProps={{
                                inputProps: {
                                  maxLength: 20,
                                  minLength: 6,
                                  autoComplete: "new-password",
                                },
                              }}
                              type={showPass ? "text" : "password"}
                              name="password"
                              // label="Password*"
                              value={password}
                              onChange={(e) => this.changeInputPass(e)}
                              onKeyDown={this.handleEnter}
                              placeholder="Enter password"
                              required
                            />
                            <i
                              onClick={() =>
                                this.setState({ showPass: !showPass })
                              }
                              className={`${
                                showPass
                                  ? "far fa-solid fa-eye pass-eye"
                                  : "far fa-eye fal fa-eye-slash pass-eye"
                              }`}
                            />
                          </div>
                        </div>
                      )}
                      {this.state.isPhoneNumber ? null : (
                        <div
                          className="forget-password forget-pass-block"
                          onClick={() => {
                            this.setState({
                              showForgotPassword: true,
                              showInputForm: false,
                            });
                          }}
                          onKeyDown={this.handleEnter}
                        >
                          Forgot password?
                        </div>
                      )}
                      <div className="sign-btn login-btn-wrapper">
                        <button type="submit" className="sign-in-btn">
                          Sign In
                        </button>
                        <br />
                      </div>
                      <div className="use-email-wrapper">
                        {
                          this.state.isPhoneNumber ? (
                            <>
                              <span className="email-text">
                                Use your{" "}
                                <a>
                                  email to
                                  <span
                                    className="login"
                                    onClick={this.handleEmailLogin}
                                  >
                                    Sign in &nbsp;
                                  </span>
                                </a>
                              </span>
                            </>
                          ) : null
                          // <>
                          //   <span className="email-text">
                          //     Use your mobile number to
                          //     <span className="login" onClick={this.handlePhoneLogin}>
                          //       Sign in &nbsp;
                          //     </span>
                          //   </span>
                          // </>
                        }
                      </div>
                      <div className="login-condition" style={{marginBottom: "10px"}}>
                        <p>
                          <button
                            className="sign-up-here"
                            onClick={() => this.handleShowEmailPasswordSignIn()}
                          >
                            Sign in using Mobile number and OTP instead
                          </button>
                        </p>
                      </div>
                      <div className="login-condition">
                        <p>
                          Don’t have an account?{" "}
                          <button
                            className="sign-up-here"
                            onClick={() => this.handleSignUp()}
                          >
                            Buyer Sign up here
                          </button>
                        </p>
                        <p>
                          <button
                            className="sign-up-here"
                            onClick={() => this.handleSellerContact()}
                          >
                            Seller contact us
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </>
          </div>
        </div>) : 
        <div
          className={
            "signup-form signup-frm-wrp user-login login-page-wrapp " +
            (this.state.showSignUpForm ? "hide-signin" : "") +
            (showOTPform ? "otp-open" : "")
          }
        >
          {this.state.showLoader ? (
            <Loader message={this.state.showMessage} />
          ) : null}
          <Helmet>
            <style>
              {`
           .facebook-button-holder, .google-button-holder {
            position: relative;
            margin-bottom:20px;
           }
           .facebook-button-holder button, .google-button-holder button {
            width:100%;
           }
            .facebook-button-holder span button, .google-button-holder button:first-child {
              position: absolute;
              left: 0;
              top: 0;
              z-index: 15;
              width: 100%;
              height: 100%;
              opacity:0 !important;
              
            }
            `}
            </style>
          </Helmet>
          <button
            className="close-btn"
            onClick={() => this.props.closePopup(true)}
          >
            <i className="fal fa-times-circle"></i>
          </button>

          <div
            className="inner-sign sign-in-without-img"
            style={showOTPform ? { justifyContent: "space-around" } : {}}
          >
            <>
              <div className="sign-form forget-password-signin-form login-page-wrapp">
                {this.state.showForgotPassword && (
                  <ForgotPass
                    forgotPassword={this.props.forgotPassword}
                    forgotpasswordotp={this.props.forgotPasswordOtp}
                    forgotpasswordresend={this.props.resendOTP}
                    setPassword={this.props.setPassword}
                    forgotMsg={this.props.forgotMsg}
                    closePopup={this.props.closePopup}
                    setState={(key, val) => this.setState({ [key]: val })}
                    time={time}
                  />
                )}
                {this.state.otpForm ? (
                  <OTPForm
                    OTP={OTP}
                    setState={(key, val) => this.setState({ [key]: val })}
                    submitOTP={this.submitOTP}
                    time={time}
                    resendMobOTP={this.resendMobOTP}
                    resendEmail={this.resendEmail}
                    mobileNumber={this.state.email}
                    switchScreen={this.switchLoginScreen}
                    backHandler={this.backHandler}
                  />
                ) : null}
                {this.state.showRegisterForm ? (
                  <LoginWithOtp
                    mobile={this.state.email}
                    login={this.mobileLogin}
                    getType={this.state.showLoginPopup}
                  />
                ) : null}
                {showInputForm && (
                  <form onSubmit={(e) => this.formSubmit(e, "user")}>
                    <div className="detail-from">
                      <div className="user-detail-edit user-signin-details">
                        <div className="logo-wrapper">
                          <Link to="/" className="logo-wrap">
                            <img
                              src="/assets/images/metal-buy-logoo.png"
                              alt="logo"
                            />
                          </Link>
                        </div>
                      </div>
                      {this.state.isPhoneNumber ? (
                        <>
                          <div className="enter-mobile-label">
                            Email / Mobile no.
                          </div>
                          <div className="form-input number-code-wrapper sign-in-mobile">
                            <span className="number-code">+91</span>
                            <TextField
                              variant="outlined"
                              required
                              type="text"
                              name="email"
                              // label="Mobile Number"
                              placeholder="Enter email or mobile no."
                              value={email}
                              onChange={(e) => this.changeInput(e)}
                              onKeyDown={this.handleEnter}
                              maxLength="10"
                            />
                            {this.state.emailError ? (
                              <span className="error">
                                Please Enter Valid Email
                              </span>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <>
                          <LoginUsingOtp/>
                        </>
                      )}
                      
                      
                      <div className="login-condition" style={{marginBottom: "10px"}}>
                        <p>
                          <button
                            className="sign-up-here"
                            onClick={() => this.handleShowEmailPasswordSignIn()}
                          >
                            Sign in using email and password instead
                          </button>
                        </p>
                      </div>
                      <div className="login-condition">
                        <p>
                          Don’t have an account?{" "}
                          <button
                            className="sign-up-here"
                            onClick={() => this.handleSignUp()}
                          >
                            Buyer Sign up here
                          </button>
                        </p>
                        <p>
                          <button
                            className="sign-up-here"
                            onClick={() => this.handleSellerContact()}
                          >
                            Seller contact us
                          </button>
                        </p>
                      </div>
                    </div>
                  </form>
                )}
              </div>
            </>
          </div>
        </div>
        }
        
        {this.state.showSignUpForm ? (
          <Signup
            switchScreen={this.handleLogin}
            closePopup={this.closePopup}
          />
        ) : null}
        {this.state.showSellerContactForm ? <SellerContact closePopup={this.closePopup} /> : null}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: (payload, completion) =>
      dispatch(loginRequest(payload, completion)),
    signupByMobile: (payload, completion) =>
      dispatch(signupByMobile(payload, completion)),
    getToken: (payload, completion) => dispatch(getToken(payload, completion)),
    socialLogin: (payload, completion) =>
      dispatch(socialLogin(payload, completion)),
    forgotPassword: (payload, completion) =>
      dispatch(forgotPassword(payload, completion)),
    forgotPasswordOtp: (payload, completion) =>
      dispatch(forgotPasswordOtp(payload, completion)),
    resendOTP: (payload, completion) =>
      dispatch(resendOTP(payload, completion)),
    setPassword: (payload, data, completion) =>
      dispatch(setPassword(payload, data, completion)),
    getProfileBannerInfo: () => dispatch(getProfileBannerInfo()),
    OTPVerify: (payload, completion) =>
      dispatch(OTPVerify(payload, completion)),
    OTPLoginVerify: (payload, completion) =>
      dispatch(OTPLoginVerify(payload, completion)),
    resendMobileOTP: (payload, compilation) =>
      resendMobileOTP(payload, compilation),
    resendEmailLink: (payload, compilation) =>
      resendEmailLink(payload, compilation),
  };
};

const mapStateToProps = (state, ownProps) => ({
  showLoginPopup: state.generalInfo.showLoginPopup,
  status: state.userInfo.error.status,
  errData: state.userInfo.error.errData,
  forgotMsg: state.userInfo.forgot,
});

export default withRouter(
  withToast(connect(mapStateToProps, mapDispatchToProps)(Login))
);

function withToast(Component) {
  return function WrappedComponent(props) {
    const toastFuncs = useToasts();
    return <Component {...props} {...toastFuncs} />;
  };
}
