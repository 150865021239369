import React from "react";
import { Link } from "react-router-dom";
import {
  calculateDiscount,
  priceWithSymbol,
} from "../../../../utils/constants";
import moment from "moment";
import swal from 'sweetalert';

const MyListingCard = (props) => {
  console.log('props ',props);
  const [options, openOptions] = React.useState(false);
  const [highLight, setHighLight] = React.useState(false);
  function useComponentVisible(event) {
    const ref = React.useRef(false);
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        openOptions(false);
      }
    };
    React.useEffect(() => {
      window.addEventListener("mousedown", handleClickOutside);
      return () => {
        window.removeEventListener("mousedown", handleClickOutside);
      };
    });
    return { ref };
  }

  React.useEffect(() => {
    if (highLight) {
      setTimeout(() => {
        setHighLight(false);
        openOptions(false);
      }, 5000);
    }
  }, [highLight]);

  const { ref } = useComponentVisible(false);
  const low_stack = props.low_stack ? props.low_stack : 5;
  return (
    <div className="list-content">
      {props.type === "active" ? (
        <Link
          to={`/product/${props.categoryName}/${props.id}/?cat=${props.categorySlug}`}
          className="image-value"
        >
          {props.mainImage ? <img src={props.mainImage} alt={props.title} /> :
            <img src="\assets\images\avtar.png" alt="img"></img>
          }

        </Link>
      ) : (
        <span
          className="image-value"
          onClick={() => {
            setHighLight(true);
            openOptions(true);
          }}
        >
          {props.mainImage ? <img src={props.mainImage} alt={props.title} /> :
            <img src="\assets\images\avtar.png" alt="img"></img>
          }

        </span>
      )}

      <Link
        to={`/product/${props.categoryName}/${props.id}/?cat=${props.categorySlug}`}
        className="price-value order-price-wrp"
      >
        <div className="product-base-price">
          <div className="product-naming pro-new"><span className="tab-hide">Name:</span> {props.title}
          </div>
          <div className="base-price-new pro-new">
            <span className="tab-hide">Price:</span>
            &#8377; {props.product_base_price}
          </div>
        </div>
        <div className="product-maker">{props.brandName}</div>
        <div className="product-price">{calculatePrice(props)}</div>
      </Link>

      {
        props.Product_status === "Disable" ?
          <div className={"qty-value qty-wrap qty-tble-new qty-new-value pro-new " + (props.qty <= low_stack && " red")}>
            Disabled
          </div>

          : <div className={"qty-value qty-wrap qty-tble-new qty-new-value pro-new " + (props.qty <= low_stack && " red")}>
            <span className="tab-hide">Qty:</span>
            {props.qty === 0 ? "Out Of Stock" : props.qty}
            <div className="d-none">QTY</div>
          </div>
      }

      {/* {props.like && props.like !== 0 ? (
        props.type !== "active" ? (
          <div
            className="like-value"
            onClick={() => swal("Please active this product")}
          >
            {props.like}
            <div className="d-none">Likes</div>
          </div>
        ) : (
          <div className="like-value">
            <Link
              to={(location) => ({
                ...location,
                pathname: "/profile/my-listing/active/likes",
                search: `?p_id=${props.id}`,
                state: { ...location.state, p_id: props.id },
              })}
            >
              {props.like}
              <div className="d-none">Likes</div>
            </Link>
          </div>
        )
      ) : (
        <div
          className="like-value"
          onClick={() => {
            props.type !== "active"
              ? swal("Please active this product")
              : swal("You can't make offer on this product")
          }}
        >
          0<div className="d-none">Like</div>
        </div>
      )} */}

      <div className="view-value pro-new">
        <span className="tab-hide">Views:</span>
        {props.watch_count}
        <div className="d-none">Views</div>
      </div>

      <div className="view-value pro-new">
        <span className="tab-hide">Size:</span>
        {props.product_size}
        <div className="d-none">Size</div>
      </div>

      <div className="view-value pro-new">
        <span className="tab-hide">Delivery Type:</span>
        {props.deliveryType}
        <div className="d-none">Delivery Type</div>
      </div>

      <div className="update-value update-list-value">
        <span className="tab-hide">updated On:</span>
        <div className="created-date">
          {
            moment(props.updatedAt).calendar({
              sameDay: 'DD/MM/YYYY h:mm a',
              nextDay: 'DD/MM/YYYY h:mm a',
              nextWeek: 'DD/MM/YYYY h:mm a',
              lastDay: 'DD/MM/YYYY h:mm a',
              lastWeek: 'DD/MM/YYYY h:mm a',
              sameElse: 'DD/MM/YYYY h:mm a'
            })
          }


          {props.type === "unApproved" ? (
            <div className="update-status">
              {props.Product_status}
            </div>
          ) : null}
        </div>
      </div>
      <div className="list-nav">
        <div className="dropdown-wrap" ref={ref}>
          <div
            className="dropdown-btn"
            onClick={() => openOptions(!options)}
          ></div>
          <ul className={"list-options " + (options ? "list-active" : "")}>
            <li
              className="list-link"
              onClick={() => {
                props.editProduct(props.id, props.type, props.categoryName);
                openOptions(false);
              }}
            >
              Edit
            </li>
            <li
              className="list-link"
              onClick={() => {
                props.deleteProduct(props.id);
                openOptions(false);
              }}
            >
              Delete
            </li>
            {props.type === "active" ? (
              <li
                className="list-link"
                onClick={() =>
                  props.addToInactive({
                    action: "inactive",
                    productId: props.id,
                  })
                }
              >
                Inactive
              </li>
            ) : props.type === "inactive" ? (
              <li
                className={"list-link " + (highLight ? "vibration" : "")}
                onClick={() =>
                  props.addToActive({ action: "active", productId: props.id })
                }
              >
                Active
              </li>
            ) :
              // props.type === "draft" ? (
              //   <li
              //     className={"list-link " + (highLight ? "vibration" : "")}
              //     onClick={() =>
              //       props.addToActive({ status: "active", productId: props.id })
              //     }
              //   >
              //     List
              //   </li>
              // ) : 
              null
            }
          </ul>
        </div>
      </div>
    </div>
  );
};
export default MyListingCard;

const calculatePrice = (props) => {
  return props.sell_price && props.sell_price !== props.current_store_price ? (
    <>
      <span className="buy-info">{priceWithSymbol(props.sell_price)}</span>
      <span className="old-price">
        ({priceWithSymbol(props.current_store_price)})
      </span>
      &nbsp;
      <span className="discount">
        {calculateDiscount(props.current_store_price, props.sell_price)}% OFF
      </span>
    </>
  ) : (props.current_store_price ?
    <span className="buy-info">
      {priceWithSymbol(props.current_store_price)}
    </span> : null

  );
};
